
import { useTranslation } from "react-i18next";
import Footer from "../Footer";
import Header from "../Header";
import { slang } from "../Utils";

const Card = function ({ title, letter, items }) {

    return (

        <div className="card border-success m-3" style={{ marginBottom: "20px", fontSize: "1.4em" }}>

            <div className="card-header border-success text-center" style={{ backgroundColor: "bisque" }}>
                <p className="display-6" style={{ marginTop: "20px" }}>{title}</p>
            </div>

            <div className="card-body fs-4">

                <div className="row g-0">
                    <div className="col-md-4">
                        <img src={"/images/" + letter + ".jpg"} className="img-fluid rounded" alt={"group function " + letter} />
                    </div>
                    <div className="col-md-8">

                        <ul className="list-group list-group-flush">

                            {items.map((item, idx) => (<li key={idx} className="list-group-item text-left">
                                {item}
                            </li>))}

                        </ul>
                    </div>
                </div >
            </div >
        </div >

    )
}


const BreebarFunc = function (props) {

    const { t } = useTranslation();

    const list_func = function (append, nb) {

        const result = [];

        for (let i = 1; i < nb + 1; i++) {

            result.push(t("bb.func_" + append + i))
        }

        return result;
    }

    const HeaderAndFooterRext = () => {
        return <p className="fs-3 text-center lh-lg" >{t("bb.func0")} <a className="btn btn-success fs-4" href="/breebar_price">{t("bb.price_title")}</a></p>
    }

    return <div>
        <Header user={props.user} />

        <div className="body fs-5 p-3">

            <h1 className="text-center my-3">{t("bb.func_title")}</h1>

            <HeaderAndFooterRext />

            <div className="mx-auto text-center embed-responsive embed-responsive-4by3">
                <video className="p-1 border border-success" allowFullScreen controls style={{ maxHeight: "500px" }}>
                    {slang(
                        <source src="breebar_fr.mp4" type="video/mp4" />,
                        <source src="breebar_en.mp4" type="video/mp4" />
                    )
                    }
                    Your browser does not support the video tag.
                </video>
            </div>


            <div className="row row-cols-1 m-3 g-4">

                {[["a", 9], ["d", 5], ["b", 7], ["c", 4], ["e", 4]].map((f) => (
                    <div key={f[0]} className="col">
                        <Card
                            letter={f[0]}
                            title={t("bb.func_" + f[0] + "0")}
                            items={list_func(f[0], f[1])}
                        />
                    </div>
                ))}
            </div>

            <HeaderAndFooterRext />

        </div>


        <Footer />

    </div>
}

export default BreebarFunc;