
import { useState, useEffect } from "react";
import { MyInput, MySelectMultiple, MySelectSearch } from "../FormElements";
import { displaySnackbar, liste_priv, slang } from "../Utils";
import { useTranslation } from "react-i18next";

const proxyUrl = require("../../../package.json").proxy;

export const modeStat = {
    ventes: 1,
    benef: 2,
    tickets: 3,
    tickets_suppr: 4,
}

const ActivityFilter = function ({ modeActuel, user, setShowFilter, current_selection, setCurrent_Selection, fetchData }) {

    const { t } = useTranslation()

    const [doss_clts, setDoss_clts] = useState([])
    const [datas, setDatas] = useState([])

    // Fetch DossierClient
    useEffect(() => {

        (async () => {

            // compose doss_clts_array
            const lecture_priv_doss_clt = function () {

                // donne tous les idDossierClients pour lesquels priv_dossier_stats est présent

                const liste_doss = []

                if (!user.privileges) return liste_doss

                user.privileges.forEach(p => {

                    if ((p.idDossierClient > 0) && (p.idData === 0)) {

                        if (p.priv.includes(liste_priv.dossier.stats_ventes) || p.priv.includes(liste_priv.dossier.stats_benef)) liste_doss.push(p.idDossierClient)
                    }
                })

                return liste_doss

            }

            let crit_doss_clt = lecture_priv_doss_clt()

            if (crit_doss_clt.length === 0) crit_doss_clt = [0]

            try {
                const response = await fetch(proxyUrl + '/doss_clts_criteria', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        crit_doss_clts: { idDossierClient: { $in: crit_doss_clt } }
                    })
                });

                const data = await response.json();

                // S'il n'y a qu'un seul dossier client alors on ne doit pas se fatiguer à le sélectionner
                if (data.length === 1) {
                    setCurrent_Selection({...current_selection, idDossierClient : data[0].idDossierClient})
                }

                setDoss_clts(data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [user.privileges, current_selection, setCurrent_Selection])

    // Fetch Data
    useEffect(() => {

        const fetchData = async () => {
            try {

                const response = await fetch(proxyUrl + '/datas/' + current_selection.idDossierClient)

                const data = await response.json();

                setDatas(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [current_selection.idDossierClient, modeActuel])


    // Refresh Selection
    const refresh_selection = function (myName, myValue) {

        const newSelection = { ...current_selection, [myName]: myValue };

        setCurrent_Selection(newSelection);
    }

    if (modeActuel === 0) return <></>

    return <div className="col fs-5">
        <div className="row">

            <div className="col">

                {(doss_clts.length === 1) &&
                    <p className="display-5 text-primary">
                        {doss_clts[0].nomClient}
                        <span className="small"> id_{doss_clts[0].idDossierClient}</span>
                    </p>
                }

                {(doss_clts.length !== 1) &&

                    <MySelectSearch
                        myName="idDossierClient"
                        refresh_itemToSave={refresh_selection}
                        idGroupe={1}
                        myLabel={t("activity.doss_clt")}
                        data={doss_clts.map((d) => ([d.idDossierClient, d.idDossierClient + " - " + d.nomClient, (d.idDossierClient === current_selection.idDossierClient)]))}
                        func={parseInt}
                        withDefaulValue={true}
                    />
                }
            </div>

            {(current_selection.idDossierClient > 0) && <>

                <div className="col">

                    <MySelectMultiple
                        myName="idData"
                        refresh_itemToSave={refresh_selection}
                        idGroupe={2}
                        myLabel="Data"
                        data={datas.map((d) => ([d.idData, d.idData + " - " + d.denomination.substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                        func={parseInt}
                    />

                </div>
            </>
            }
        </div>

        {(current_selection.idDossierClient > 0) && <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
                <MyInput
                    myName="dateDebut"
                    refresh_itemToSave={refresh_selection}
                    idGroupe={3}
                    myLabel={t("activity.date_start")}
                    myType="date"
                    func={String}
                />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
                <MyInput
                    myName="dateFin"
                    refresh_itemToSave={refresh_selection}
                    idGroupe={4}
                    myLabel={t("activity.date_end")}
                    myType="date"
                    func={String}
                />
            </div>

        </div>
        }

        <button type="button" className={"btn btn-lg btn-info mb-3"} onClick={() => {

            if ((current_selection.idDossierClient === 0) || (!current_selection.idDossierClient)) {

                displaySnackbar(
                    slang("Veuillez choisir un dossier client", "Please select a customer file"),
                    slang("Elément manquant", "Field required"),
                    "danger")
                return
            }

            if ((current_selection.dateDebut === "") || (current_selection.dateFin === "")) {
                displaySnackbar(
                    slang("Attention les dates sont requises", "Dates are required"),
                    slang("Elément manquant", "Field required"),
                    "danger")
                return
            }

            setShowFilter(false)

            fetchData(modeActuel === 3 || modeActuel === 4) // entre parenthèses c'est le justTicket
        }}>
            {t("activity.showdata")}
        </button>

    </div>
}

export default ActivityFilter;