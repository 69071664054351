import { useState, useEffect, useCallback } from "react";
import { MyInput, MySelectSearch, MySwitch } from "../FormElements";
import { displaySnackbar, liste_priv, slang, userHasPriv } from "../Utils";
import { useTranslation } from "react-i18next";

import ListeUsersAccesCloud from "./ListeUsersAccesCloud";
const proxyUrl = require("../../../package.json").proxy;

const SettingsPriv = function (props) {

    const { t } = useTranslation()

    const [current_selection, setCurrent_Selection] = useState({
        idDossierClient: 0,
        idUser: 0,
        idData: 0,
        newpassword: "",
        newpassword_conf: "",
    })

    const [resetPwdOpen, setResetPwdOpen] = useState(false)
    const [current_priv, setCurrent_priv] = useState([])

    const [users, setUsers] = useState([])
    const [refreshUser, setRefreshUser] = useState(false)
    const [doss_clts, setDoss_clts] = useState([])
    const [datas, setDatas] = useState([])

    // Fetch Users
    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/users');
                const data = await response.json();

                setUsers(data);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [refreshUser])

    // Fetch DossierClient
    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/doss_clts');
                const data = await response.json();
                setDoss_clts(data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [])

    // Fetch Data
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(proxyUrl + '/datas/' + current_selection.idDossierClient);
                const data = await response.json();

                setDatas(data.map((d) => ([d.idData, d.idData + " - " + d.denomination.substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)])));

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [current_selection.idDossierClient])

    // hasPriv : useCallback c'est pour que la fonction hasPriv ne soit pas recréée à chaque fois, depuis qu'elle est en dépendance du useEffect de "Feed current priv"
    const hasPriv = useCallback((idPriv) => {
        // indique si l'utilisateur sélectionné possède un privilège

        const current_user = users[users.findIndex((u) => { return u.idUser === current_selection.idUser })]
        const contains_item = function (idDossierClient, idData) {

            if (!current_user?.privileges) return false;

            const priv_group_index = current_user.privileges.findIndex((p) => {
                return ((p.idDossierClient === idDossierClient) && (p.idData === idData))
            })

            if (priv_group_index === -1) return false;

            return current_user.privileges[priv_group_index].priv.includes(idPriv)
        }

        // (idDossierClient, idData)

        if (idPriv < 10) { // priv digibobo

            return contains_item(0, 0)

        } else if (idPriv < 20) {

            return contains_item(current_selection.idDossierClient, 0)

        } else if (idPriv < 30) {

            return contains_item(0, current_selection.idData)

        }
    }, [current_selection, users]);

    // Feed current priv
    useEffect(() => {

        const result = []

        Object.keys(liste_priv).forEach((k) => {

            Object.keys(liste_priv[k]).forEach((j) => {

                const priv = liste_priv[k][j];

                const myValue = hasPriv(priv);

                if (myValue) result.push(priv);

                if (document.getElementById("Switch" + priv)) {

                    document.getElementById("Switch" + priv).checked = myValue;
                }
            })
        })

        setCurrent_priv(result)

    }, [current_selection, hasPriv])

    // Compose les priv de l'utilisateur pour enregistrement
    const composePriv_to_save = function () {

        const result = [];

        const selected_user = users[users.findIndex((u) => { return u.idUser === current_selection.idUser })];

        // create privilege if necessary and add the current privilege
        const compose_priv = function (p, idDossierClient, idData) {

            const idx = result.findIndex((p) => {
                return ((p.idDossierClient === idDossierClient) && (p.idData === idData))
            })

            if (-1 === idx) {

                result.push({
                    idDossierClient: idDossierClient,
                    idData: idData,
                    priv: [p],
                })
            } else {
                const idx2 = result[idx].priv.findIndex((p_item) => {
                    return (p_item === p)
                })

                if (-1 === idx2) {

                    result[idx].priv.push(p);
                } else {

                    // Rien à faire de ce côté vu que le privilège spécifique en question existe déjà
                }

            }
        }

        if (current_selection.idUser === 0) return

        // load unselected privileges (idDossierClient non sélectionné et idData non sélectionné)
        if (selected_user.privileges) {

            selected_user.privileges.forEach(group => {
                if (group.idData > 0 && group.idData !== current_selection.idData) {
                    result.push(group)
                }
                if (group.idDossierClient > 0 && group.idDossierClient !== current_selection.idDossierClient) {
                    result.push(group)
                }
            })
        }

        current_priv.forEach((p) => {

            // (idDossierClient, idData, idActivite)

            if (p < 10) { // priv digibobo

                compose_priv(p, 0, 0)

            } else if (p < 20) {

                compose_priv(p, current_selection.idDossierClient, 0)

            } else if (p < 30) {

                compose_priv(p, 0, current_selection.idData)

            }
        })

        return result
    }

    // Save User Privilege
    const save_user_priv = async function () {

        try {
            const url = proxyUrl + '/user_priv'

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    idUser: current_selection.idUser,
                    priv: composePriv_to_save()
                }),
            })

            if (response.status === 201) {
                displaySnackbar(slang("Privilèges utilisateur enregistrés", "User privileges saved"), slang("Succès", "success"), "success")

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }

        } catch (error) {
            console.error(error);
        }
    }


    // Reset de Mot de passe
    const handleResetPassword = async (e) => {

        e.preventDefault();

        if (current_selection.newpassword !== current_selection.newpassword_conf) {
            displaySnackbar(slang("Le mot de passe et sa confirmation ne correspondent pas", "password and confirmation doesn't match"), "Password", "warning");
            return
        }

        try {

            const response = await fetch(proxyUrl + "/user_pwd_reset", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    idUser: current_selection.idUser,
                    newpassword: current_selection.newpassword,
                }),
            })

            if (response.status === 200) {
                displaySnackbar(slang("Mot de passe correctement modifié", "Password saved"), "Password", "warning");

                setResetPwdOpen(false);
            } else {

                displaySnackbar(slang("Problème dans l'enregistrement de password", "Error during password modification"), "Password", "danger");
            }

        } catch (error) {
            console.error(error);
        }
    };

    // Refresh Selection
    const refresh_selection = function (myName, myValue) {

        let newSelection = { ...current_selection, [myName]: isNaN(myValue) ? 0 : myValue };

        if (myName === "idDossierClient") {
            newSelection = { ...newSelection, idData: 0 };
        }

        if (myName === "idUser") {
            setCurrent_priv()
        }

        setCurrent_Selection(newSelection);
    }

    // Refresh current_priv
    const refresh_current_priv = function (myName, myValue) {

        let newPrivSelection

        if (myValue) {

            newPrivSelection = current_priv
            newPrivSelection.push(myName)

        } else {

            newPrivSelection = current_priv.filter(function (p) { return p !== myName })
        }

        setCurrent_priv(newPrivSelection)
    }

    return <div className="body">

        <h2>Privileges</h2>

        <div className="row justify-content-center">

            <div className="col-auto">

                <MySelectSearch
                    myName="idUser"
                    refresh_itemToSave={refresh_selection}
                    idGroupe={1}
                    myLabel="User"
                    data={users.map((u) => ([u.idUser, u.idUser + " " + (u.username ?? "no_name") + " - " + u.email]))}
                    func={parseInt}
                />

                <div className={current_selection.idUser === 0 ? "d-none" : ""}>

                    <MySelectSearch
                        myName="idDossierClient"
                        refresh_itemToSave={refresh_selection}
                        idGroupe={2}
                        myLabel={t("settings.doss_clt")}
                        data={doss_clts.map((d) => ([d.idDossierClient, d.idDossierClient + " - " + d.nomClient]))}
                        func={parseInt}
                    />

                    <div className={current_selection.idDossierClient === 0 ? "d-none" : ""}>

                        <MySelectSearch
                            myName="idData"
                            refresh_itemToSave={refresh_selection}
                            idGroupe={3}
                            myLabel="Data"
                            data={datas}
                            func={parseInt}
                        />
                    </div>
                </div>

            </div>

            {current_selection.idUser > 0 &&
                <div className="col">


                    {userHasPriv(props.user, liste_priv.digibobo.admin) &&
                        <button className="btn btn-lg btn-outline-primary" onClick={() => setResetPwdOpen(!resetPwdOpen)}>Switch</button>
                    }

                    {!resetPwdOpen &&
                        <>

                            <div className="row">

                                {Object.keys(liste_priv).map((k, idx) => {

                                    if ((k === "digibobo") && (!userHasPriv(props.user, liste_priv.digibobo.admin))) return <div key={idx}></div>

                                    if ((k === "dossier") && (current_selection.idDossierClient === 0)) return <div key={idx}></div>

                                    if ((k === "data") && (current_selection.idData === 0)) return <div key={idx}></div>

                                    return <div key={idx} className="col">

                                        <h4>{k}</h4>

                                        {Object.keys(liste_priv[k]).map((j, idx2) => {

                                            const priv = liste_priv[k][j];

                                            return <div key={idx2}>
                                                <MySwitch
                                                    myLabel={j}
                                                    myName={priv}
                                                    refresh_itemToSave={refresh_current_priv}
                                                    idGroupe={priv}
                                                />
                                            </div>
                                        })}
                                    </div>
                                })}
                            </div>

                            <div className="row">

                                <button className="btn btn-lg fs-4 btn-success border-dark col-6" onClick={save_user_priv}>{t("general.save") + " Privileges"}</button>
                            </div>
                        </>
                    }

                    {(resetPwdOpen && userHasPriv(props.user, liste_priv.digibobo.admin)) &&
                        <div className="col-xl-8 col-12">

                            <h2>Reset Password</h2>

                            <form onSubmit={handleResetPassword}>

                                <MyInput
                                    myName="newpassword"
                                    refresh_itemToSave={refresh_selection}
                                    idGroupe={40}
                                    myLabel="new password"
                                    myType="password"
                                />

                                <MyInput
                                    myName="newpassword_conf"
                                    refresh_itemToSave={refresh_selection}
                                    idGroupe={41}
                                    myLabel="confirmation"
                                    myType="password"
                                />


                                <button className="btn btn-warning btn-lg border-dark fs-4" type="submit">{t("general.save") + " Password"}</button>
                            </form>
                        </div>}
                </div>
            }

        </div>

        <h2>Accès Cloud</h2>

        <ListeUsersAccesCloud current_user={props.user} users={users} refreshUser={refreshUser} setRefreshUser={setRefreshUser} />

    </div >
}

export default SettingsPriv;