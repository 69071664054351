import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import MyToast from './MyToast';
import { userHasAtLeast } from "./Utils";

const Header = function (props) {

    const {t} = useTranslation()

    const isLogged = Boolean(props.user?.email);

    let current_username = "No_name"

    if (props.user?.username) current_username = props.user.username.substring(0,10)

    return <div className="header fs-5 sticky-top">

        <nav id="nav_header">
            <ul className="fs-4 d-flex justify-content-around">

                <li>
                    <a className="align-middle" href="/">
                        <img style={{ height: "1.8rem", width: "auto" }} src="/images/logo_digibobo1.png" alt="Logo Digibobo" />

                    </a>
                </li>
                {!isLogged &&
                    <>
                        <li><a className="align-middle" href="/shop_main">{t("header.shop")}</a></li>
                        <li><a className="align-middle" href="/shop_pc_composer">{t("header.pc")}</a></li>
                        <li><a className="align-middle" href="/breebar_func">Breebar</a></li>
                    </>
                }

                {isLogged &&
                    <>
                        {userHasAtLeast(props.user, false) &&
                            <li><a className="align-middle" href="/pending">{t("header.pending")}</a></li>
                        }
                        {userHasAtLeast(props.user, true) &&
                            <li><a className="align-middle" href="/activity">{t("header.activity")}</a></li>
                        }
                        <li><a className="align-middle" href="/settings">{t("header.settings")}</a></li>
                    </>
                }
                {!isLogged &&
                    <li><a className="align-middle" href="/login">Login</a></li>
                }
                {isLogged &&
                    <li><a className="align-middle link-danger" href="/logout">{current_username + " Logout"}</a></li>
                }
                <li>
                    <LanguageSelector />
                </li>
            </ul>

        </nav>

        <MyToast />
    </div>
}

export default Header;