import { useTranslation } from "react-i18next";
import { Toast } from 'bootstrap';
import { Trans } from "react-i18next";
import { MyInput } from "./FormElements";
import { useState } from "react";
import i18next from "i18next";

const proxyUrl = require("../../package.json").proxy;

export const liste_priv = {
    digibobo: {
        admin: 1, // Peut donner les privilèges les plus étendus et donc aussi ce privilège admin à un autre utilisateur
        doss_clt: 2, // Créer des dossiers clients, data et souscriptions [renew souscription + cancel souscription (passage de -1 à 0 dans une souscription licence illimitée)]
        posts: 3, // Gère l'actualité Digibobo
        shop: 4, // Gère les articles vendus
        ordi: 5, // Gère les ordinateurs, leurs processeurs et leurs composantes
    },
    dossier: {
        produit: 11, // pointage bigcode des Produits et des Clients, modification des PV et PA
        stats_ventes: 12, // accède au volet stats avec ce dossier client
        stats_benef: 13, // dans le volet stats peut voir le bénéfice
    },
    data: {
        remise: 21, // Peut valider une Remise online
        suppr: 22, // Peut valider une Suppr de ticket online
        //pwd: 23, // Peut remettre le mot de passe d'un utilisateur local à 0000
        // ça demande de fetch les bb_users d'une db précise, pour une option secondaire !
    },
}

export const rangDossiers = {
    menu_logo: 1, // idData > 0, id = 0
    menu_photo: 2, // idData > 0, id = 0
    menu_produit: 3, // idData > 0, id = IdProduit

    post: 4, // idData = 0
    shop_product: 5, // idData = 0
    ordi: 6, // idData = 0
    online_user: 7, // idData = 0
}

// renvoie le bon texte en fonction de la langue actuelle (français ou anglais)
export const slang = (texte_fr, texte_en) => {

    switch (i18next.language) {
        case "fr":
            return texte_fr

        case "en":
            return texte_en

        default:
            return texte_en
    }
}

// permet de savoir si l'utilisateur indiqué dispose du privilège indiqué
export const userHasPriv = (user, idPriv, idDossierClient, idData) => {

    const contains_item = function (idDossierClient, idData) {

        if (!user?.privileges) return false;

        const priv_group_index = user.privileges.findIndex((p) => {
            return ((p.idDossierClient === idDossierClient) && (p.idData === idData))
        })

        if (priv_group_index === -1) return false;

        return user.privileges[priv_group_index].priv.includes(idPriv)
    }

    // (idDossierClient, idData)

    if (idPriv < 10) { // priv digibobo

        return contains_item(0, 0)

    } else if (idPriv < 20) { // priv doss_clt

        return contains_item(idDossierClient, 0)

    } else if (idPriv < 30) { // priv data

        return contains_item(0, idData)

    }
}

// permet de savoir si l'utilisateur indiqué dispose d'au moins 1 privilège dans la catégorie spécifiée
export const userHasAtLeast = (user, dossier_ou_data) => {

    if (!user?.privileges) return false;

    const priv_group_index = user.privileges.findIndex((p) => {
        if (dossier_ou_data) {

            return ((p.idDossierClient > 0) && (p.idData === 0))
        } else {

            return ((p.idDossierClient === 0) && (p.idData > 0))
        }
    })

    if (priv_group_index === -1) return false;

    return true
}

// permet de donner un tableau de toutes les cibles (doss_clts ou data) pour lequelles user a le priv indiqué
export const userPrivTargets = (user, idPriv) => {

    if (!user?.privileges) return [];
    if (idPriv < 10) return []

    let result = []

    for (let i = 0; i < user.privileges.length; i++) {
        const privilege = user.privileges[i];
        // (idDossierClient, idData)

        let priv_exist_here = false

        for (let j = 0; j < privilege.priv.length; j++) {

            priv_exist_here = (privilege.priv[j] === idPriv)

            if (priv_exist_here) break
        }

        if (priv_exist_here) {

            if (idPriv < 20) { // priv doss_clt

                result.push(privilege.idDossierClient)

            } else if (idPriv < 30) { // priv data

                result.push(privilege.idData)
            }
        }
    }
    return result
}

export const MySpinner = ({ show, myText }) => {

    return <div>

        {!show && <></>}

        {show &&
            <div className="d-flex justify-content-center">
                <span className="text-warning small">{(myText === undefined) ? "" : (myText + "... ")}</span>
                <div className="spinner-grow text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        }
    </div>
}

export const MyScrollButton = () => {

    return <button className="btn btn-lg scrollButton" onClick={() => window.scrollTo(0, 0)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="brown" className="bi bi-arrow-up-square" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
        </svg>
    </button>
}

export const my_fetch_POST = async (url, body, second_time = false) => {

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body),
        });

        return response

    } catch (error) {
        if (!second_time) {

            return await my_fetch_POST(url, body, true) // je lance une 2ème fois parce que les fetch POST envoient d'abord une erreur lors de la toute 1ère tentative
            // L'erreur est liée aux Cors mais je n'ai pas su comment la résoudre
        }
        console.error(error);
    }
}

// Composant permettant d'enregistrer une commande et de se rediriger vers whatsapp
export const OrderForm = ({ total, content }) => {

    const { t } = useTranslation()
    const [phone, setPhone] = useState("")
    const [confirmOrder, setConfirmOrder] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const create_order = async () => {

        if (phone.length === 0) {

            document.getElementById("inputGroup1").focus()
            displaySnackbar(
                slang("Svp veuillez indiquer votre numéro de téléphone", "Please fill your phone number"),
                slang("Numéro de téléphone requis", "Phone number is required"),
                "warning")
            return
        }

        try {

            await my_fetch_POST(proxyUrl + "/order", {
                phone: phone,
                total: total,
                content: content,
            })

            // Pour le suivi de la conversion Google
            window.gtag('event', 'conversion', { 'send_to': 'AW-756128754/9GWmCKegqI8ZEPK3xugC' })

            setShowSpinner(true)

            document.getElementById("redirect_to_whatsapp").click()

            setTimeout(() => {
                setShowSpinner(false)
            }, 2000);


        } catch (error) {
            console.error(error);
        }
    }

    const refresh_selection = (name, value) => {
        setPhone(value)
    }

    return <div className="row m-1">

        {!showSpinner &&
            <>

                <a href={"https://wa.me/237678532492/?text=order_" + phone} hidden id="redirect_to_whatsapp">whatsapp</a>

                <button
                    className={"btn w-75 mx-auto mb-3 fs-4 btn-" + (confirmOrder ? "outline-dark border-gray" : "success")}
                    onClick={() => setConfirmOrder(!confirmOrder)}
                >
                    {t("utils.btn_order")}
                </button>

                {confirmOrder &&
                    <>
                        <MyInput
                            myName="phone"
                            refresh_itemToSave={refresh_selection}
                            idGroupe={1}
                            myLabel={t("utils.phone")}
                            myType="text"
                        />

                        <button className="btn fs-4 btn-success" onClick={create_order}>{t("utils.btn_confirm_order")}</button>
                    </>
                }
            </>
        }

        <MySpinner show={showSpinner} />
    </div>
}


// Composant permettant d'afficher "x élément affiché" avec gestion du pluriel
export const NbItems = ({ count }) => {
    return <span style={{ color: "darkblue" }}>
        &nbsp;

        {count + " "}

        <Trans i18nKey="general.items" count={count}>
            section_texte_simple_id0
        </Trans>
    </span>
}

// scrolle jusqu'à l'élément indiqué en paramètre via son id
export const jump = (elt_id) => {
    var top = document.getElementById(elt_id).offsetTop; //Getting Y of target element
    window.scrollTo(0, top);                        //Go there directly or some transition
}

export const displaySnackbar = function (message, title, severity = "dark") { // For Snackbar

    document.getElementById("toast_title").innerText = title;
    document.getElementById("toast_message").innerText = message;

    const list = document.getElementById("myToast").classList;

    list.replace(list[1], "text-bg-" + severity);

    const toast = new Toast(document.getElementById("myToast")) // No need for options; use the default options

    toast.show();
}

export function GetListe_Categories() {

    const { t } = useTranslation()

    return [
        [1, t("utils.graph")],
        [2, "SSD"],
        [3, "HDD"],
        [4, t("utils.ecran")],
        [5, t("utils.ecran_tact")],
        [6, t("utils.cla")],
        [7, t("utils.sou")],
        [8, t("utils.clasou")],
        [9, t("utils.sono")],
        [0, "----"],
        [11, "RAM DDR 1"],
        [12, "RAM DDR 2"],
        [13, "RAM DDR 3"],
        [14, "RAM DDR 4"],
    ]
};

export function format_date(date_string) {

    const date_options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false
    };

    const date_options_same_day = {
        hour: "numeric",
        minute: "numeric",
        hour12: false
    };

    if (!date_string) return ""

    const the_date = new Date(date_string);
    const today = new Date()

    return Intl
        .DateTimeFormat(i18next.language, (today.toDateString() === the_date.toDateString()) ? date_options_same_day : date_options)
        .format(Date.parse(the_date))
}

export function format_date_nohour(date_string) {

    const date_options = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    if (!date_string) return ""

    const the_date = new Date(date_string);
    const today = new Date()

    if (today.toDateString() === the_date.toDateString()) {
        return slang("aujourd'hui", "today")
    }

    return Intl
        .DateTimeFormat(i18next.language, date_options)
        .format(Date.parse(the_date))
}

export function digitGroup(num) {
    var numSplit, int, dec, sign, snum;

    if (!num) return 0

    sign = (Math.sign(num) === -1 ? '- ' : '');

    num = Math.abs(num);
    snum = num.toFixed(2);
    numSplit = snum.split('.')

    dec = numSplit[1];

    snum = numSplit[0];
    int = '';

    while (snum.length > 3) {

        int = ' ' + snum.substring(snum.length - 3, snum.length) + int;

        snum = snum.substring(0, snum.length - 3)
    }

    int = snum + int;

    return sign + int + ((dec !== '00') ? ',' + dec : '');
}