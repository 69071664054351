import { useTranslation } from "react-i18next";

const Footer = function (props) {

    const { t } = useTranslation()

    const year = new Date().getFullYear();

    return <div className="footer mt-3 fs-4 text-center" style={{ padding: "8vw" }}>

        <p>{t("footer.first_row_a")}<a className="link-underline link-underline-opacity-0" style={{ color: "green" }} href={"https://wa.me/237678532492/?text=Votre message à Digibobo"}>Whatsapp</a>{t("footer.first_row_b")}</p>

        <p>{t("footer.second_row_a")}
            <span className="text-primary">
                <a className="text-decoration-none" href="tel:00237678532492">(+237) 678 532 492</a>
            </span>
            {t("footer.second_row_b")}
        </p>

        <table className="table table-hover table-secondary table-sm">
            <thead>
                <tr>
                    <th scope="col">{t("footer.table_jours")}</th>
                    <th scope="col">{t("footer.table_heures")}</th>
                </tr>
            </thead>
            <tbody>

                <tr >
                    <td>{t("footer.table_lun_sam")}</td>
                    <td>{t("footer.table_hours1")}</td>
                </tr>
                <tr >
                    <td>{t("footer.table_jeudi")}</td>
                    <td>{t("footer.table_hours2")}</td>
                </tr>
            </tbody>
        </table>


        <p style={{ marginTop: "4vh" }}>Made with ❤️ by digibobo<span>. {(year === 2023) ? year : "2023 - " + year}</span></p>
    </div>
}

export default Footer;