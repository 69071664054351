import { useEffect, useState } from "react";
import { MySelect, MyCheckBox } from "../FormElements";
import { useTranslation, Trans } from "react-i18next";
import { MySpinner, OrderForm, digitGroup, my_fetch_POST } from "../Utils";
import Header from "../Header";
const proxyUrl = require("../../../package.json").proxy;

const kDesktop = 1;
const kLaptop = 2;
const kAll_in_one = 3;

/* IMAGES
Local
0, 0, 0	Breebar
0, 1, iddata 	Logo Entreprise
0, 2, iddata	Image fond Menu Appli et PSF
0, 3, iddata	Image bas ticket, généralement qrcode
id, 1, iddata	Pers portrait
id, 2, iddata	Pers paysage
id, 3, iddata	Produits

Online
id, 4, 0	Posts /posts
id, 5, 0	Articles boutiques
id, 6, 0	Ordis /ordi
*/

const Resultat = function ({ ordi, ordiProc, order }) {

    const { t } = useTranslation()

    const img_path = proxyUrl + "/uploads/0/" + ordi.idOrdi + "-6.jpg"

    const no_ram_selected = (order.ram === 0)
    const no_hdd_selected = (order.hdd === 0)
    const no_ssd_selected = (order.ssd === 0)

    const two_columns = function (first, second) {

        return <li className="list-group-item">
            <div className="row">
                <div className="col-4 p-0">
                    <small className="text-muted">{first}</small>
                </div>
                <div className="col-8">

                    {second}
                </div>
            </div>
        </li>
    }

    const one_row = function (content) {
        return <li className="list-group-item">
            {content}
        </li>
    }

    const red_row = function (content) {
        return <li className="list-group-item">
            <span style={{ color: "red" }}>
                {content}
            </span>
        </li>
    }


    let proc_name = ""

    for (let i = 0; i < ordiProc.length; i++) {
        const elt = ordiProc[i];

        if (elt.idProc === ordi.idProc) {
            proc_name = elt.procName
            break
        }
    }


    return (
        <div className="col-lg-4 col-md-6 col-sm-12 p-0 fs-6 p-2">
            <div className="card text-center border-primary" style={{ marginBottom: "20px", fontSize: "1.4em" }}>

                <div className="card-header border-primary">
                    <h3 className="display-6" style={{ marginTop: "20px" }}>{ordi.denomination}</h3>
                </div>

                <div className="card-body">

                    <h2 className="display-6">{digitGroup(ordi.price_calc)}<small> FCFA</small></h2>

                    <img className="card-img-top my-1" style={{ maxHeight: "250px", width: "auto", maxWidth: "100%" }} src={img_path} alt="Card cap" />

                    <ul className="list-group list-group-flush text-left">

                        {/* PROCESSEUR */}
                        {two_columns(t("ordi.composer_proc"), proc_name + ((ordi.processeurGeneration) ? " Gen " + ordi.processeurGeneration : ""))}

                        {/* RAM */}

                        {two_columns("Ram (DDR" + ordi.typeram + ")", order.ram + " Go")}

                        {/* CONSTAT Sans Ram */}
                        {no_ram_selected &&

                            red_row(t("ordi.composer_no_ram"))
                        }

                        {/* SSD */}
                        {order.ssd > 0 &&
                            two_columns("SSD:", order.ssd + " Go")
                        }

                        {/* HDD */}
                        {order.hdd > 0 &&
                            two_columns("HDD:", order.hdd + " Go")
                        }

                        {/* CONSTAT Sans Disque Dur */}

                        {no_hdd_selected && no_ssd_selected &&

                            red_row(t("ordi.composer_no_disk"))
                        }

                        {/* ECRAN */}
                        {(order.ecr > 0 || ordi.typeOrdi !== kDesktop) &&
                            two_columns(t("ordi.ecran"), <>
                                {ordi.typeOrdi === kDesktop && order.ecr + "''"}
                                {ordi.typeOrdi > kDesktop &&
                                    <span>
                                        {ordi.ecranTaille + "''"}
                                        {ordi.ecranTactile && <span> {t("ordi.tactile")}</span>}
                                    </span>
                                }
                            </>)
                        }

                        {/* CONSTAT Sans Ecran */}

                        {(order.ecr === 0 && ordi.typeOrdi === kDesktop) &&

                            red_row(t("ordi.composer_no_screen"))
                        }

                        {/* GRAPHIQUE DE BASE */}

                        {one_row(<small>{(ordi.graphMarque ?? "-") + " " + t("ordi.graphDedie") + " " + ordi.graphDedie + "Mo"}</small>)}

                        {/* ACCESSOIRES : CARTE GRAPHIQUE, SOU, CLASOU, SONO */}

                        {ordi.accessoires.map((acc, i) => {
                            return <li key={i} className="list-group-item text-center">
                                {acc.denomination}
                            </li>
                        })
                        }


                    </ul>

                </div >

                <div className="card-footer">
                    <OrderForm total={ordi.price_calc} content={{ ordi: { ...ordi }, order: { ...order }, ordiProc: { ...ordiProc } }} />
                </div>
            </div >
        </div>
    )
}

const TitreSection = function ({ titre }) {
    return <div className="display-6" style={{ marginTop: "30px", marginBottom: "10px" }}>{titre}</div>
}

export default function OrdiComposer(props) {

    const { t } = useTranslation()

    const [deckOpen, setDeckOpen] = useState(false);

    const [info1, setInfo1] = useState(false);

    const [info2, setInfo2] = useState(false);

    const [ordis, setOrdis] = useState([]);

    const [ordiProcActifs, setOrdiProcActifs] = useState([]);

    const [composantesActives, setComposantesActives] = useState([])

    const [showSpinner, setShowSpinner] = useState(false)

    const default_order = {
        type_ordi: 0,
        type_proc: 0,
        graph: 0, // -1 pour dire carte par défaut, 0 non sélectionné
        ram: 0,
        ssd: 0,
        hdd: 0,
        ecr: 0,
        ecranTactile: false,
        cla: 0, // -1 pour dire cla par défaut, 0 non sélectionné
        sou: 0, // -1 pour dire sou par défaut, 0 non sélectionné
        clasou: 0, // -1 pour dire clasou par défaut, 0 non sélectionné
        sono: 0, // -1 pour dire clasou par défaut, 0 non sélectionné
    }

    const [orderToSave, setOrderToSave] = useState(default_order)

    const toggleInfo1 = function () {
        setInfo1(!info1)
    }

    const toggleInfo2 = function () {
        setInfo2(!info2)
    }

    // fetch Active Composantes
    // fetch Composantes
    useEffect(() => {

        const fetchComposantesActives = async () => {
            try {
                const response = await fetch(proxyUrl + '/composantes_active');
                const data = await response.json();
                setComposantesActives(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchComposantesActives();

    }, [])

    // fetch Active Proc types
    useEffect(() => {

        const fetchOrdiProc = async () => {
            try {
                const response = await fetch(proxyUrl + '/proc_active');
                const data = await response.json();

                setOrdiProcActifs(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchOrdiProc();

    }, [])

    // fetch Ordis according to type_ordi, type_proc, ecr, ecr_tact
    useEffect(() => {

        const fetchOrdis = async (second_time = false) => {

            if (second_time) console.log("enters_second time")

            if (orderToSave.type_ordi === 0) return

            let criteria = { active: true };

            criteria = { "typeOrdi": orderToSave.type_ordi, ...criteria }

            if (orderToSave.type_proc !== 0) {
                criteria = { "idProc": orderToSave.type_proc, ...criteria }
            }

            if (orderToSave.type_ordi > 1) // !desktop = laptop ou all-in-one
            {
                if (orderToSave.ecr !== 0) criteria = { ...criteria, "ecranTaille": { $gte: orderToSave.ecr, $lte: orderToSave.ecr + 1 } }

                if (orderToSave.ecr_tact === true) criteria = { ...criteria, "ecranTactile": orderToSave.ecr_tact }
            }

            try {

                setShowSpinner(true)
                const response = await my_fetch_POST(proxyUrl + '/ordis_criteria', criteria);
                setShowSpinner(false)

                const data = await response.json();

                setOrdis(data);

            } catch (error) {

                console.error(error);
            }
        };

        fetchOrdis();

    }, [orderToSave.type_ordi, orderToSave.type_proc, orderToSave.ecr, orderToSave.ecr_tact])


    // #region COMPOSE LISTS

    // 1. Graph, 2. SSD, 3. HDD, 4. Ecran, 5. Ecran Tactile, 6. Clavier wireless, 7. Souris wireless, 8. ClaSou wireless, 9. Sono, 11-14. RAM Desktop DDR1-4

    const composantesActives_filtered = {
        "GRAPH": composantesActives.filter((comp) => comp.category === 1),
        "RAM": composantesActives.filter((comp) => [11, 12, 13, 14].includes(comp.category)),
        "SSD": composantesActives.filter((comp) => comp.category === 2),
        "HDD": composantesActives.filter((comp) => comp.category === 3),
        "ECR": composantesActives.filter((comp) => comp.category === 4),
        "ECR_TACT": composantesActives.filter((comp) => comp.category === 5),
        "CLA": composantesActives.filter((comp) => comp.category === 6),
        "SOU": composantesActives.filter((comp) => comp.category === 7),
        "CLASOU": composantesActives.filter((comp) => comp.category === 8),
        "SONO": composantesActives.filter((comp) => comp.category === 9),
    }

    const LISTE_TYPE_ORDI = [
        [kDesktop, "Desktop"],
        [kLaptop, "Laptop"],
        [kAll_in_one, "All-in-one"],
    ];

    // Pour RAM, SSD et HDD
    // Permet d'enlever les doublons et de filtrer directement la liste selon le type d'ordi sélectionné
    const liste_memory = function (liste) {

        let LISTE_DEJA = []

        return liste.filter(comp => {

            if (orderToSave.type_ordi === 0) return false

            if ([false, comp.forDesktop, comp.forLaptop, comp.forAllInOne][LISTE_TYPE_ORDI[orderToSave.type_ordi - 1][0]]) {

                if (!LISTE_DEJA.includes(comp.memory)) {

                    LISTE_DEJA.push(comp.memory)
                    return true
                }
            }
            return false
        }).map((comp) => {

            return [comp.memory, comp.memory + " Go"]
        })
    }

    const LISTE_RAM = liste_memory(composantesActives_filtered.RAM)

    const LISTE_SSD = liste_memory(composantesActives_filtered.SSD)

    const LISTE_HDD = liste_memory(composantesActives_filtered.HDD)

    const LISTE_ECR = (orderToSave.type_ordi === kLaptop
        ?

        [13, 14, 15, 16, 17].map((ecr) => ([ecr, ecr + " " + t("ordi.pouce")]))
        :
        [17, 19, 22, 24, 27].map((ecr) => ([ecr, ecr + " " + t("ordi.pouce")]))
    )

    const LISTE_GRAPH = [[-1, t("ordi.graph_defaut")], ...composantesActives_filtered.GRAPH.map((gph) => {
        return [gph.idComposante, "Carte " + gph.denomination + " dédiée " + gph.graphDedie + " Mo"]
    })]

    const LISTE_CLA = [[-1, t("ordi.cla_defaut")], ...composantesActives_filtered.CLA.map((cla) => {
        return [cla.idComposante, t("ordi.cla_wireless") + " - " + cla.denomination]
    })]

    const LISTE_SOU = [[-1, t("ordi.sou_defaut")], ...composantesActives_filtered.SOU.map((sou) => {
        return [sou.idComposante, t("ordi.sou_wireless") + " - " + sou.denomination]
    })]

    const LISTE_CLASOU = [[-1, t("ordi.clasou_defaut")], ...composantesActives_filtered.CLASOU.map((clasou) => {
        return [clasou.idComposante, t("ordi.clasou_wireless") + " - " + clasou.denomination]
    })]

    const LISTE_SONO = [[-1, t("ordi.sono_defaut")], ...composantesActives_filtered.SONO.map((sono) => {
        return [sono.idComposante, sono.denomination]
    })]
    //#endregion


    const extract_first_price = (myList) => {
        if (myList.length === 0) return 0
        return myList[0].price
    }

    const getRAM_price = function (current_ordi_type, current_ordi_type_RAM) {

        // RAM compatibles

        let liste_ram = [];

        switch (current_ordi_type) {
            case 1: // Desktop
                liste_ram = composantesActives_filtered.RAM.filter((ram) => (((ram.category === current_ordi_type_RAM + 10) && (orderToSave.ram === ram.memory)) && ram.forDesktop))
                // pour savoir quelle catégorie de composante RAM viser

                break;

            case 2: // Laptop
                liste_ram = composantesActives_filtered.RAM.filter((ram) => ((ram.category === current_ordi_type_RAM + 10) && (orderToSave.ram === ram.memory)) && ram.forLaptop)
                break;

            case 3: // All-in-one
                liste_ram = composantesActives_filtered.RAM.filter((ram) => ((ram.category === current_ordi_type_RAM + 10) && (orderToSave.ram === ram.memory)) && ram.forAllInOne)
                break;

            default:
                break;
        }

        return extract_first_price(liste_ram);
    };

    const getPrice = function (ordi) {

        // Calcule le prix final pour l'ordi passé en paramètre*

        let price = ordi.price;

        const price_item = function (fieldName, elt_crit) {

            if (orderToSave[fieldName] > 0) { // si on a même d'abord sélectioné
                const liste = composantesActives_filtered[fieldName.toUpperCase()].filter((elt) => elt[elt_crit] === orderToSave[fieldName])
                price += extract_first_price(liste)
            }
        }

        // SSD
        price_item("ssd", "memory");

        // HDD
        price_item("hdd", "memory");

        // RAM
        // ici on est obligé de sélectionner car la ram a été mise par défaut à 4Go dans LISTE_RAM
        price += getRAM_price(ordi.typeOrdi, ordi.typeram);

        // ECRAN
        price_item("ecr", "ecranTaille");

        // GRAPHIQUE
        price_item("graph", "idComposante");

        // CLAVIER
        price_item("cla", "idComposante");

        // SOURIS
        price_item("sou", "idComposante");

        // CLAVIER + SOURIS
        price_item("clasou", "idComposante");

        // SONO
        price_item("sono", "idComposante");

        return price
    }

    const getAccessoires = function () {

        let acc = [];

        const list_ACC = ["graph", "cla", "sou", "clasou", "sono"]
        const list_LIST = [LISTE_GRAPH, LISTE_CLA, LISTE_SOU, LISTE_CLASOU, LISTE_SONO]

        list_ACC.map((elt, i) => {

            if (orderToSave[elt] > 0) {
                const idx = list_LIST[i].findIndex((a) => a[0] === orderToSave[elt])

                acc.push({ denomination: list_LIST[i][idx][1] })
            }

            return null
        })
        return acc;
    }

    const getResults = function () {

        // Donne les résultats à afficher

        // Bah c'est déjà la liste des ordis fetch il faut juste calculer les prix + ordonner la liste avec .sort()

        return ordis.map((ordi) => { return { ...ordi, price_calc: getPrice(ordi), accessoires: getAccessoires() } }).sort(function (a, b) {
            return a.price_calc - b.price_calc;
        });
    }

    const refresh_orderToSave = function (myName, myValue) {

        const newOrder = { ...orderToSave, [myName]: myValue };

        // Gestion des cases qui impactent d'autres cases quand on les remplit

        switch (myName) {
            case "type_ordi":
                switch (myValue) {
                    case 2: // Ici on passe à Laptop, All-in-one
                    case 3:
                        newOrder.graph = 0;
                        newOrder.hdd = 0;
                        break;

                    case 1: // Ici on passe à Desktop
                        newOrder.ecranTactile = false;
                        break;

                    default:
                        break;
                }
                break;

            case "cla":
                if (!(myValue === "-1" || myValue === 0)) { // Si on a choisi un kit clavier + souris en particulier

                    newOrder.clasou = 0;
                }
                break;

            case "sou":
                if (!(myValue === "-1" || myValue === 0)) { // Si on a choisi un kit clavier + souris en particulier

                    newOrder.clasou = 0;
                }
                break;

            case "clasou":
                if (!(myValue === "-1" || myValue === 0)) { // Si on a choisi un kit clavier + souris en particulier

                    newOrder.cla = 0;
                    newOrder.sou = 0;
                }
                break;

            default:
                break;
        }

        setOrderToSave(newOrder);
    }

    // Bouton qui affiche le nombre de résultats

    const ButtonShowResults = function () {
        return ((!deckOpen) && (orderToSave.type_ordi > 0) && (ordis.length > 0)) ?
            <button className={"fs-4 btn btn-lg btn" + (deckOpen ? "-outline" : "") + "-primary"} onClick={() => setDeckOpen(true)}>{t("ordi.btn_show_results")}</button>
            :
            <></>
    }

    return <div className="col">

        <Header user={props.user} />

        <div className="sticky-top z-1 row" style={{ backgroundColor: "white", paddingTop: "60px" }}>

            <div className="btn-group col-10 my-2 mx-auto" role="group" aria-label="Navigation buttons">
                <button className={"fs-4 btn btn-outline-success btn-lg"} onClick={() => setDeckOpen(false)}>{t("ordi.btn_modify_config")}</button>
                <button className="fs-4 btn btn-light border-dark btn-lg" onClick={() => window.location.reload(false)}>{t("ordi.btn_reset_config")}</button>

                <ButtonShowResults />
            </div>

            {/* Nombre de résultats */}

            <div className="mb-3 p-2 fs-3 text-center" style={{ backgroundColor: "bisque" }}>
                <div className="col">

                    <span className="text-primary fw-bolder">
                        {ordis.length + " "}
                    </span>

                    <Trans i18nKey="ordi.results" count={ordis.length}>
                        section_texte_simple_id0
                    </Trans>
                </div>
            </div>

            <MySpinner show={showSpinner} myText={t("general.loading") + " Pcs"} />

        </div>

        <div className={(deckOpen ? "d-none " : "") + "col-10 mx-auto p-3"}>

            {/* BASE */}

            <TitreSection titre="Base" />

            <div className="mx-3">

                <MySelect
                    myName="type_ordi"
                    refresh_itemToSave={refresh_orderToSave}
                    idGroupe={1}
                    myLabel="Type"
                    data={LISTE_TYPE_ORDI}
                    func={parseInt}
                />
                <MySelect
                    myName="type_proc"
                    refresh_itemToSave={refresh_orderToSave}
                    idGroupe={2}
                    myLabel={t("utils.proc")}
                    data={ordiProcActifs.map((proc) => [proc.idProc, proc.procName])}
                    func={parseInt}
                />
            </div>


            {/* MEMOIRE */}

            {orderToSave.type_ordi > 0 &&
                <div>

                    <TitreSection titre={<p onClick={toggleInfo1}>{t("ordi.composer_title1")} <img style={{ height: "30px", width: "auto" }} src="/images/Info-Button.svg" alt="info" /></p>} />

                    <div className="mx-3">
                        {info1 &&
                            <small>
                                <p>
                                    <Trans i18nKey="ordi.composer_info1_1">
                                        La <strong>RAM</strong> (Random Access Memory) permet à l'ordinateur d'exécuter plusieurs tâches à la fois. Pour un usage courant 4Go suffisent, mais pour des travaux professionels optez pour au moins 8Go.
                                    </Trans>
                                </p>
                                <p>
                                    <Trans i18nKey="ordi.composer_info1_2">
                                        Les <strong>SSD</strong> (Random Access Memory) et les <strong>HDD</strong> (Hard Disk Drive) forment l'<strong>espace de stockage</strong>.
                                    </Trans>
                                </p>
                                <p>
                                    {t("ordi.composer_info1_3")}
                                </p>
                            </small>
                        }

                        <MySelect
                            myName="ssd"
                            refresh_itemToSave={refresh_orderToSave}
                            idGroupe={4}
                            myLabel="SSD"
                            data={LISTE_SSD}
                            func={parseInt}
                        />

                        {orderToSave.type_ordi === 1 &&
                            <MySelect
                                myName="hdd"
                                refresh_itemToSave={refresh_orderToSave}
                                idGroupe={5}
                                myLabel="HDD"
                                data={LISTE_HDD}
                                func={parseInt}
                            />
                        }

                        <MySelect
                            myName="ram"
                            refresh_itemToSave={refresh_orderToSave}
                            idGroupe={3}
                            myLabel="RAM"
                            data={LISTE_RAM}
                            func={parseInt}
                        />


                    </div>
                </div>
            }

            {/* AFFICHAGE */}

            {orderToSave.type_ordi > 0 &&
                <div>

                    <TitreSection titre={<p onClick={toggleInfo2}>{t("ordi.composer_title2")} <img style={{ height: "30px", width: "auto" }} src="/images/Info-Button.svg" alt="info" /></p>} />


                    <div className="mx-3">
                        {info2 &&
                            <small>
                                <p>
                                    <Trans i18nKey="ordi.composer_info2">
                                        Volet <strong>Carte Graphique</strong> : pour un usage courant la carte graphique intégrée suffit, mais pour des travaux professionels optez pour une carte spécifique.
                                    </Trans>
                                </p>
                            </small>
                        }


                        <MySelect
                            myName="ecr"
                            refresh_itemToSave={refresh_orderToSave}
                            idGroupe={6}
                            myLabel={t("ordi.ecran")}
                            data={LISTE_ECR}
                            func={parseInt}
                        />

                        {orderToSave.type_ordi > 1 &&
                            <MyCheckBox
                                myLabel={t("ordi.tactile")}
                                myName="ecr_tact"
                                refresh_itemToSave={refresh_orderToSave}
                                idGroupe={7}
                            />
                        }

                        {orderToSave.type_ordi === 1 &&
                            <MySelect
                                myName="graph"
                                refresh_itemToSave={refresh_orderToSave}
                                idGroupe={8}
                                myLabel={t("ordi.dediee_texte")}
                                data={LISTE_GRAPH}
                                func={parseInt}
                            />
                        }
                    </div>
                </div>
            }

            {/* ACCESSOIRES */}

            {orderToSave.type_ordi > 0 &&
                <>
                    <TitreSection titre={t("ordi.composer_title3")} />

                    <div className="mx-3">

                        {LISTE_CLA.length > 1 &&
                            <MySelect
                                myName="cla"
                                refresh_itemToSave={refresh_orderToSave}
                                idGroupe={9}
                                myLabel={t("ordi.cla")}
                                data={LISTE_CLA}
                                func={parseInt}
                            />
                        }

                        {LISTE_SOU.length > 1 &&
                            <MySelect
                                myName="sou"
                                refresh_itemToSave={refresh_orderToSave}
                                idGroupe={10}
                                myLabel={t("ordi.sou")}
                                data={LISTE_SOU}
                                func={parseInt}
                            />
                        }

                        <MySelect
                            myName="clasou"
                            refresh_itemToSave={refresh_orderToSave}
                            idGroupe={11}
                            myLabel={t("ordi.clasou")}
                            data={LISTE_CLASOU}
                            func={parseInt}
                        />

                        {/* Je remettrai ça plus tard quand j'aurai mes propres éléments achetés dans mon local

                                <MySelect
                                    myName="sono"
                                    refresh_itemToSave={refresh_orderToSave}
                                    idGroupe={12}
                                    myLabel={t("utils.sono")}
                                    data={LISTE_SONO}
                                    func={parseInt}
                                /> */}

                    </div>
                </>
            }
        </div>

        <div className="text-center my-3">

            <ButtonShowResults />
        </div>


        {/* Affichage des cartes résultats */}

        {
            deckOpen &&

            <div className="text-center mx-3 fs-4">

                <div className="row gx-1">
                    {getResults().map((ordi, i) => (<Resultat key={i} ordi={ordi} ordiProc={ordiProcActifs} order={orderToSave} />))}
                </div>
                <br />
                <p>
                    <Trans i18nKey="ordi.composer_greeting">
                        section_texte_simple_id0<strong>section_id1</strong>section_texte_simple_id2<strong>section_3</strong>section_4<strong>section_6</strong>section_7
                    </Trans>
                </p>
            </div>

        }
    </div >
}