import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyInput } from '../FormElements';
import { displaySnackbar, format_date, slang } from '../Utils';

const proxyUrl = require("../../../package.json").proxy;


function SettingsUser({ user }) {

    const { t } = useTranslation()

    const [formOpen, setFormOpen] = useState(false)

    const [userToSave, setUserTosave] = useState({
        idUser: user.idUser,
        username: user.username,
        email: user.email,
        phone: user.phone,
        oldpassword: "",
        newpassword: "",
        newpassword_conf: "",
    })

    // Modification de User
    const handleModifiyUser = async (e) => {

        e.preventDefault();

        try {

            const response = await fetch(proxyUrl + "/user", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(userToSave),
            })

            if (response.status === 201) window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    // Modification de Mot de passe
    const handleModifyPassword = async (e) => {

        e.preventDefault();

        if (userToSave.newpassword !== userToSave.newpassword_conf) {
            displaySnackbar(slang("Le mot de passe et sa confirmation ne correspondent pas", "password and confirmation doesn't match"), "Password", "danger");
            return
        }

        try {

            const response = await fetch(proxyUrl + "/user_pwd_change", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(userToSave),
            })

            if (response.status === 200) {
                displaySnackbar(slang("Mot de passe correctement modifié", "Password saved"), "Password", "warning");

                setFormOpen(false);
            } else {

                displaySnackbar(slang("Problème dans l'enregistrement de password", "Error during password modification"), "Password", "danger");
            }

        } catch (error) {
            console.error(error);
        }
    };

    const refresh_userToSave = function (myName, myValue) {

        const newComp = { ...userToSave, [myName]: myValue }
        setUserTosave(newComp);
    }

    if (!user.email) return <></>

    return (
        <div className="col-11 mx-auto">
            <h2>{t("settings.user_info_title")}</h2>

            <div className="row">

                <div className="col btn-lg fs-4">

                    <p>Code : {user.idUser}</p>
                    <p>{t("general.name")} : {user.username}</p>
                    <p>{t("utils.phone")} : <span className="font-monospace fs-3">{user.phone}</span></p>
                    <p className="text-primary">Date : {format_date(user.dateFin)}</p>
                    <p className="fs-5">Email : {user.email}</p>

                    <button className="btn btn-primary btn-lg fs-4 mb-3" type="button" onClick={() => setFormOpen(!formOpen)}>{t("general.modify")}</button>
                </div>

                {formOpen &&
                    <>
                        <div className="col">
                            <form onSubmit={handleModifiyUser}>

                                <div className="form-group">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text fs-4" htmlFor={"inputGroup0"}>email</label>
                                        </div>
                                        <input
                                            id={"inputGroup0"}
                                            className="form-control fs-5"
                                            name="email"
                                            value={userToSave.email}

                                            readOnly
                                        />
                                    </div>
                                </div>

                                <MyInput
                                    myName="username"
                                    refresh_itemToSave={refresh_userToSave}
                                    idGroupe={1}
                                    myLabel="name"
                                    myType="text"
                                    myValue={userToSave.username}
                                />

                                <MyInput
                                    myName="phone"
                                    refresh_itemToSave={refresh_userToSave}
                                    idGroupe={2}
                                    myLabel="phone"
                                    myType="text"
                                    myValue={userToSave.phone}
                                />

                                <button className="btn btn-success btn-lg fs-4" type="submit">{t("general.save") + " infos"}</button>
                            </form>
                        </div>

                        <div className="col me-3">
                            <form onSubmit={handleModifyPassword}>
                                <MyInput
                                    myName="oldpassword"
                                    refresh_itemToSave={refresh_userToSave}
                                    idGroupe={3}
                                    myLabel="old password"
                                    myType="password"
                                />

                                <MyInput
                                    myName="newpassword"
                                    refresh_itemToSave={refresh_userToSave}
                                    idGroupe={4}
                                    myLabel="new password"
                                    myType="password"
                                />

                                <MyInput
                                    myName="newpassword_conf"
                                    refresh_itemToSave={refresh_userToSave}
                                    idGroupe={5}
                                    myLabel="confirmation"
                                    myType="password"
                                />


                                <button className="btn btn-warning btn-lg border-dark fs-4" type="submit">{t("general.save") + " Password"}</button>
                            </form>
                        </div>
                    </>
                }
            </div>

        </div >
    );
}

export default SettingsUser;