import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import defaultPlaceholderImg from "../images/default-placeholder.png";
import { format_date } from '../Utils';

const proxyUrl = require("../../../package.json").proxy;

const PostPage = (props) => {

    const [post, setPost] = useState(null);

    const { current_idPost } = useParams();

    useEffect(() => {
        fetch(proxyUrl + "/get_post/" + current_idPost)
            .then(response => response.json())
            .then(data => setPost(data));
    }, [current_idPost]);

    return (
        <div>
            <Header user={props.user} />

            <div className="body fs-4 justify-content-center text-center" style={{ margin:"10px"}}>

                {post && (
                    <div className="col-10 mx-auto">
                        <h1>{post.header}</h1>
                        <img className="rounded" style={{ maxHeight: "400px", maxWidth: "400px" }} src={proxyUrl + "/uploads/0/" + current_idPost + "-4.jpg"} onError={(e) => { e.target.onerror = null; e.target.src = defaultPlaceholderImg }} alt="Full Size" />
                        
                        <p className="text-primary my-2">{format_date(post.createdAt)}</p>
                        <p className="text-start css-fix-linebreak">{post.content}</p>
                    </div>
                )}

            </div>

            <Footer />
        </div>
    );
};

export default PostPage;