import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MySpinner, slang } from '../Utils';

const proxyUrl = require("../../../package.json").proxy;

export default function MenuFamilies({ infoIdFamille, changeIdFamille, infoIdFamilleGroupe, idData }) {

    const { t } = useTranslation();

    const FAMILLE_SERVICE_URL = proxyUrl + "/familles_menu/" + idData;

    const [familles, setFamilles] = useState([])

    const status_colors = ["Tomato", "SeaShell"];

    const [showSpinner,
        setShowSpinner] = useState(true);


    // FETCH the families list
    useEffect(() => {

        const fetchFamilies = async () => {

            setShowSpinner(true)

            fetch(FAMILLE_SERVICE_URL)
                .then(response => response.json())
                .then(result => {
                    return result.sort(function (a, b) { // Fonction qui permet de trier les familles par ordre alphabétique
                        return a.NomFamille.localeCompare(b.NomFamille);
                    });
                })
                .then(result => {
                    setFamilles(result);
                })
                .catch(e => {
                    console.log(e);
                });

            setShowSpinner(false)
        };
        fetchFamilies();
    }, [FAMILLE_SERVICE_URL]);

    const ButtonGroup = function ({ id, nom }) {
        
        return <button
            className="btn ticket border border-warning fs-3 m-1"
            onClick={() => {
                if (infoIdFamille === -2) {

                    changeIdFamille(id)
                } else {

                    changeIdFamille(-2)
                }
            }}
            style={{ backgroundColor: status_colors[(infoIdFamille === id) ? 0 : 1] }}>

            <p>{nom}</p>

        </button>
    }

    return (
        <div className="mx-3 text-center">

            <MySpinner show={showSpinner} myText={t("general.loading") + slang(" Familles", " Families")} />

            {(infoIdFamille === -2) && <div>
                {familles.map((famille, idx) => {

                    const idFamGrp = (famille.idFamilleGroupe===undefined) ? 0 : famille.idFamilleGroupe

                    if (infoIdFamilleGroupe !== idFamGrp) return null

                    return <ButtonGroup key={idx} id={famille.IdFamille} nom={famille.NomFamille} />
                })}
            </div>
            }

            {
                (infoIdFamille !== -2) && <span>

                    {familles.map((famille, idx) => {

                        if (infoIdFamille !== famille.IdFamille) return null

                        return (
                            <button
                                key={idx}
                                className="btn ticket border border-warning fs-3 m-1"
                                onClick={() => changeIdFamille(-2)}
                                style={{ backgroundColor: status_colors[(infoIdFamille === famille.IdFamille) ? 0 : 1] }}>

                                <p>{famille.NomFamille}</p>

                            </button>
                        );
                    })}

                </span>
            }



        </div>

    );
}