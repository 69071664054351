import { useState } from 'react';
import { MyCheckBox, MyInput, MySelect } from "../FormElements";
import { GetListe_Categories, displaySnackbar, slang } from '../Utils';
import { useTranslation } from 'react-i18next';

const proxyUrl = require("../../../package.json").proxy;

function ComposanteForm(props) {

    const { t } = useTranslation()

    const current_item = props.composante;

    const bool_modify = Boolean(current_item);

    // A. idComposante: Number, // auto increment
    // B. category: Number, // 1. Graph, 2. SSD, 3. HDD, 4. Ecran, 5. Ecran Tactile, 6. Clavier wireless, 7. Souris wireless, 8. ClaSou wireless, 11-14. RAM Desktop DDR1-4
    // C. denomination: String, // au cas où cette composante a un nom spécifique. Ex: nvidia GeForce 850
    // D. memory: Number, // en Go pour les options Ram, SSD et HDD
    // E. graphDedie: Number, // en Mo
    // F. graphTotale: Number, // en Mo
    // G. price: Number, // FCFA
    // H. forLaptop: Boolean,
    // I. forDesktop: Boolean,
    // J. forAllInOne: Boolean,
    // K. ecranTaille: Number, // en pouces

    const [composanteToSave, setComposanteToSave] = useState({
        active: bool_modify ? current_item.active : false,
        category: bool_modify ? current_item.category : "0",
        denomination: bool_modify ? current_item.denomination : "",
        memory: bool_modify ? current_item.memory : 0,
        graphDedie: bool_modify ? current_item.graphDedie : 0,
        graphTotale: bool_modify ? current_item.graphTotale : 0,
        price: bool_modify ? current_item.price : 0,
        forLaptop: bool_modify ? current_item.forLaptop : false,
        forDesktop: bool_modify ? current_item.forDesktop : false,
        forAllInOne: bool_modify ? current_item.forAllInOne : false,
    })

    const refresh_composanteToSave = function (myName, myValue) {

        const newComp = { ...composanteToSave, [myName]: myValue }

        // Gestion des cases qui impactent d'autres cases quand on les remplit

        if (myName === "category") {

            switch (myValue) {
                case 1: // Ici on passe à Graphique
                    newComp.memory = 0;

                    newComp.ecranTaille = 0;
                    break;

                case 2: // Ici on passe à SDD, HDD, RAM DDR1-4
                case 3:
                case 11:
                case 12:
                case 13:
                case 14:
                    newComp.graphDedie = 0;
                    newComp.graphTotale = 0;

                    newComp.ecranTaille = 0;
                    break;

                case 4: // Ici on passe à Ecran et à Ecran Tactile
                case 5:
                    newComp.memory = 0;

                    newComp.graphDedie = 0;
                    newComp.graphTotale = 0;
                    break;

                default:
                    newComp.memory = 0;

                    newComp.graphDedie = 0;
                    newComp.graphTotale = 0;

                    newComp.ecranTaille = 0;
                    break;
            }

        }

        setComposanteToSave(newComp);
    }


    // Création et Modification
    const handleSubmit = async (e) => {
        e.preventDefault();

        const url = proxyUrl + (!bool_modify ? "/composante" : "/composante/" + current_item.idComposante);
        const method = "POST"

        // Logique pour enregistrer

        const response = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(composanteToSave),
        })

        if (response.status === 201) {
            props.refresh_list()
            displaySnackbar(slang("Modification effectuée","Saved succesfully"),"OK","success")
        }

    };


    return <div>

        {bool_modify ?
            <h2>{t("general.modify") + " " + current_item.denomination + " id_" + current_item.idComposante}</h2>
            :
            <h2>{t("general.add")}</h2>
        }

        <form className="col-lg-10 col-md-11 mx-auto border-bottom border-secondary mb-3" onSubmit={handleSubmit}>

            <div className="row">

                <div className="col-6">

                    {/* A. category: Number // 1. Graph, 2. SSD, 3. HDD, 4. Ecran, 5. Ecran Tactile, 6. Clavier wireless, 7. Souris wireless, 8. ClaSou wireless, 9. Sono 11-14. RAM DDR1-4 */}

                    <MySelect
                        myName="category"
                        refresh_itemToSave={refresh_composanteToSave}
                        idGroupe={1}
                        myLabel="Type"
                        data={GetListe_Categories()}
                        func={parseInt}
                    />

                    {/* B. denomination: String // au cas où cette composante a un nom spécifique. Ex: nvidia GeForce 850 */}

                    <MyInput
                        myName="denomination"
                        refresh_itemToSave={refresh_composanteToSave}
                        idGroupe={2}
                        myLabel={t("composante_form.name")}
                        myType="text"
                    />

                </div>

                <div className="col-6">
                    <div className="row align-items-center">

                        <div className="col-10">

                            {/* L. active: Boolean */}

                            <MyCheckBox
                                myLabel={t("composante.active")}
                                myName="active"
                                refresh_itemToSave={refresh_composanteToSave}
                            />

                            {/* G. forLaptop: Boolean */}

                            <MyCheckBox
                                myLabel={t("composante.forlaptop")}
                                myName="forLaptop"
                                refresh_itemToSave={refresh_composanteToSave}
                                idGroupe={7}
                            />

                            {/* H. forDesktop: Boolean */}

                            <MyCheckBox
                                myLabel={t("composante.fordesktop")}
                                myName="forDesktop"
                                refresh_itemToSave={refresh_composanteToSave}
                                idGroupe={8}
                            />

                            {/* I. forAllInOne: Boolean */}

                            <MyCheckBox
                                myLabel={t("composante.forallinone")}
                                myName="forAllInOne"
                                refresh_itemToSave={refresh_composanteToSave}
                                idGroupe={9}
                            />
                        </div>

                        {/* Validation */}

                        <div className="col-2">
                            <button className="btn btn-lg fs-4 btn-success" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-6">

                    {/* F. price: Number // FCFA */}

                    <MyInput
                        myName="price"
                        refresh_itemToSave={refresh_composanteToSave}
                        idGroupe={6}
                        myLabel={t("composante_form.price")}
                        myType="Number"
                        step={0.01}
                        func={parseFloat}
                    />
                </div>

                {/* C. memory: Number // en Go pour les options Ram, SSD et HDD */}
                {[2, 3, 11, 12, 13, 14].includes(composanteToSave.category) &&
                    <div className="col-6">
                        <MyInput
                            myName="memory"
                            refresh_itemToSave={refresh_composanteToSave}
                            idGroupe={3}
                            myLabel={t("composante_form.memory")}
                            myType="Number"
                            func={parseInt}
                        />
                    </div>
                }

                {/* K. ecranTaille // en pouces pour les options Ecran et Ecran Tactile */}
                {[4, 5].includes(composanteToSave.category) &&
                    <div className="col-5">
                        <MyInput
                            myName="ecranTaille"
                            refresh_itemToSave={refresh_composanteToSave}
                            idGroupe={3}
                            myLabel={t("composante_form.screen")}
                            myType="Number"
                            step={0.1}
                            func={parseFloat}
                        />
                    </div>}

            </div>

            {composanteToSave.category === 1 &&
                <div className="row">

                    <div className="col-6">

                        {/* D. graphDedie: Number // en Mo */}

                        <MyInput
                            myName="graphDedie"
                            refresh_itemToSave={refresh_composanteToSave}
                            idGroupe={4}
                            myLabel={t("composante_form.graph")}
                            myType="Number"
                            func={parseInt}
                        />
                    </div>

                    <div className="col-5">

                        {/* E. graphTotale: Number // en Mo */}

                        <MyInput
                            myName="graphTotale"
                            refresh_itemToSave={refresh_composanteToSave}
                            idGroupe={5}
                            myLabel={t("composante_form.graphtot")}
                            myType="Number"
                            func={parseInt}
                        />
                    </div>

                </div>
            }

        </form>
    </div>
}

export default ComposanteForm;