
import error404 from "./components/images/error404.png";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import LogoutPage from "./components/pages/LogoutPage";
import SettingsPage from "./components/settings/SettingsPage";
import ActivityPage from "./components/activity/ActivityPage";
import PendingPage from "./components/activity/PendingPage";
import BreebarPrice from "./components/shop/BreebarPrice";
import BreebarFunc from "./components/shop/BreebarFunc";

import { useEffect, useState } from "react";
import PostAdmin from "./components/posts/PostAdmin";
import PostPage from "./components/posts/PostPage";
import OrdiAdmin from "./components/ordi/OrdiAdmin";
import OrderPage from "./components/shop/OrderPage";
import OrdiComposer from "./components/ordi/OrdiComposer";
import ComposanteAdmin from "./components/ordi/ComposanteAdmin";
import ProcesseurAdmin from "./components/ordi/ProcesseurAdmin";
import OrderAdmin from "./components/shop/OrderAdmin";
import ShopAdmin from "./components/shop/ShopAdmin";
import ShopMain from "./components/shop/ShopMain";
import MenuProducts from "./components/menu/MenuProducts";
import ShopProduct from "./components/shop/ShopProduct";
const proxyUrl = require("../package.json").proxy;

function NoMatch() {
    let location = useLocation();

    return (
        <div className="m-3 p-3 text-center justify-content-center">
            <img className="fullImg" src={error404} alt="Error 404" />
            <h3>
                No match for
                <code>{location.pathname}</code>
            </h3>
            <a className="m-3 p-3 btn btn-lg btn-outline-primary" href="/">Home / Aller à l'Accueil</a>
        </div>
    );
}

function App() {

    const [user,
        setUser] = useState({ email: "" });

    const [userRefresh,
        setUserRefresh] = useState(false);

    useEffect(() => {

        const abortController = new AbortController();

        const fetchUserInfo = async () => {

            fetch(proxyUrl + "/get_user/", {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                signal: abortController.signal,
            })
                .then(response => response.json())
                .then(data => {

                    setUser(data)
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        // Request was cancelled
                        console.log('Request Aborted!');
                        return;
                    }
                })
        };
        fetchUserInfo();

        return () => {
            abortController.abort(); // Cancel the request if component unmounts
        };
    }, [userRefresh]);

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage user={user} />} />

                <Route path="/post/:current_idPost" element={<PostPage user={user} />} />

                <Route path="/post_admin" element={<PostAdmin user={user} />} />

                <Route path="/breebar_price" element={<BreebarPrice user={user} />} />

                <Route path="/breebar_func" element={<BreebarFunc user={user} />} />

                <Route path="/shop_main" element={<ShopMain user={user} />} />

                <Route path="/shop_pc_composer" element={<OrdiComposer user={user} />} />
                
                <Route path="/shop_product/:idProduct" element={<ShopProduct user={user} />} />

                <Route path="/shop_admin" element={<ShopAdmin user={user} />} />

                <Route path="/order_admin" element={<OrderAdmin user={user} />} />

                <Route path="/order/:current_idOrder" element={<OrderPage user={user} />} />

                <Route path="/login" element={<LoginPage refreshUser={() => setUserRefresh(!userRefresh)} />} />

                <Route path="/logout" element={<LogoutPage refreshUser={() => setUserRefresh(!userRefresh)} />} />

                <Route path="/settings" element={<SettingsPage user={user} />} />

                <Route path="/activity" element={<ActivityPage user={user} />} />

                <Route path="/pending" element={<PendingPage user={user} />} />

                <Route path="/ordi_admin" element={<OrdiAdmin user={user} />} />

                <Route path="/composante_admin" element={<ComposanteAdmin user={user} />} />

                <Route path="/processeur_admin" element={<ProcesseurAdmin user={user} />} />
                
                <Route path="/menu/:idData/:pretty_name" element={<MenuProducts />} />

                <Route path="*" element={<NoMatch />} />
            </Routes>
        </Router>
    );
}

export default App;
