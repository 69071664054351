import Header from "../Header";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import RecentPosts from "../posts/RecentPosts";
import { MyScrollButton } from "../Utils";

const HomePage = function (props) {

    const { t } = useTranslation();


    return <div>

        {/* PAGE D'ACCUEIL */}

        <div className="position-relative overflow-hidden text-center bg-light vh-100">
            <div className="position-absolute top-50 start-50 translate-middle">
                <h1 className="display-6 fw-normal">{t("home.welcome")}</h1>
                <img style={{ height: "5rem", width: "auto", marginBottom: "20px" }} src="/images/logo_digibobo1.png" alt="Logo Digibobo" />
                <p className="lead fs-3 fw-normal">{t("home.services_text")}</p>
                <a className="btn btn-lg btn-warning fs-2 p-3" href="#main_container">{t("home.btn_decouvrir")}</a>
            </div>
        </div>
        <Header user={props.user} />

        {/* PC COMPOSER ET BOUTIQUE */}

        <div className="min-vh-100 mx-auto" style={{ paddingTop: "100px", maxWidth:"1200px" }} id="main_container">
            <div className="row">

                {/* PC COMPOSER */}

                <div className="col-lg-6 col-md-10 mx-auto">

                    <div className="p-4 mb-4 bg-body-tertiary border border-dark-subtle rounded-3 text-center">
                        <h1 className="display-4">{t("home.composer_titre")}</h1>
                        <p className="lead fs-4">{t("home.composer_texte")}</p>
                        <hr className="my-4" />

                        <a className="text-decoration-none" href={"/shop_pc_composer"}>
                        <img className="mw-100 rounded" style={{ maxHeight: "300px" }} src={"/images/pc2.jpg"} alt="Full Size" />
                        </a>

                        <p className="fs-4 mt-3">
                        {t("home.composer_texte_bas")}
                        </p>
                        <p className="lead">
                            <a className="btn btn-secondary btn-lg fs-4" href="/shop_pc_composer" role="button">{t("home.btn_pc_composer")}</a>
                        </p>
                    </div>
                </div>

                {/* BOUTIQUE */}

                <div className="col-lg-6 col-md-10 mx-auto">

                    <div className="p-4 mb-4 bg-body-tertiary border border-dark-subtle rounded-3 text-center">
                        <h1 className="display-4">{t("home.shop_titre")}</h1>
                        <p className="lead fs-4">{t("home.shop_texte")}</p>
                        <hr className="my-4" />

                        <a className="text-decoration-none" href={"/shop_main"}>
                        <img className="mw-100 rounded" style={{ maxHeight: "300px" }} src={"/images/xPrinter.jpg"} alt="Full Size" />
                        </a>

                        <p className="fs-4 mt-3">
                        {t("home.shop_texte_bas")}
                        </p>
                        <p className="lead">
                            <a className="btn btn-secondary btn-lg fs-4" href="/shop_main" role="button">{t("home.btn_boutique")}</a>
                        </p>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-auto mx-auto">

                    <a className="btn btn-lg btn-warning fs-2 p-3" href="#second_container">{t("home.btn_actus")}</a>
                </div>
            </div>
        </div>

        <div className="row mx-auto" style={{ paddingTop: "100px", maxWidth:"1200px" }} id="second_container">

            {/* ACTUALITES */}

            <div className="sticky-top z-1" style={{ paddingTop: "60px" }}>
                <h1 className="text-center display-6 p-1" style={{ margingTop: "80px", color: "brown", backgroundColor: "white" }}>{t("home.news")}</h1>
            </div>



            <div className="col-lg-8 col-md-10 mx-auto">

                <div className="p-4 mb-4 bg-body-tertiary border border-dark-subtle rounded-3 text-center">
                    <h1 className="display-4">{t("home.bb_titre")}</h1>
                    <p className="lead fs-4">{t("home.bb_texte")}</p>
                    <hr className="my-4" />

                    <a className="text-decoration-none" href={"/breebar_price"}>
                    <img className="mw-100 rounded" style={{ maxHeight: "400px" }} src={"/images/menu_breebar.jpg"} alt="Full Size" />
                    </a>

                    <p className="fs-4 mt-3">
                    {t("home.bb_texte_bas")}
                    </p>
                    <p className="lead">
                        <a className="btn btn-secondary btn-lg fs-4" href="/breebar_price" role="button">{t("home.btn_breebar")}</a>
                    </p>
                </div>
            </div>

            {/* POSTS RECENTS */}

            <RecentPosts />

        </div>

        <Footer />

        <MyScrollButton />
    </div>
}

export default HomePage;