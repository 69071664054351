import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import SettingsPriv from "./SettingsPriv";
import SettingsDossier from "./SettingsDossier";
import SettingsBigcode from "./SettingsBigcode";
import SettingsUser from "./SettingsUser";
import { liste_priv, userHasPriv } from "../Utils";
import { UserAccessExpired } from "../AccesDenied";

const SettingsPage = function (props) {

    const [onglet, setOnglet] = useState(0);

    const { t } = useTranslation();

    const handleChoice = function (choice) {
        setOnglet(choice);
    };

    return <div>
        <Header user={props.user} />

        {((new Date(props.user.dateFin)) < Date.now()) ?
            <UserAccessExpired user={props.user} />
            :
            <div className="body">

                <h1>{t("header.settings")}</h1>

                <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">

                    <button type="button" className={"btn btn-lg btn-" + (onglet === 1 ? "primary" : "outline-secondary")} onClick={() => handleChoice(1)}>{t("settings.onglet1")}</button>

                    {((userHasPriv(props.user, liste_priv.digibobo.ordi)) ||
                        (userHasPriv(props.user, liste_priv.digibobo.shop) ||
                            userHasPriv(props.user, liste_priv.digibobo.posts))) &&
                        <button type="button" className={"btn btn-lg btn-" + (onglet === 2 ? "primary" : "outline-secondary")} onClick={() => handleChoice(2)}>BACK OFFICE</button>
                    }

                    {(userHasPriv(props.user, liste_priv.digibobo.doss_clt)) &&
                        <>
                            <button type="button" className={"btn btn-lg btn-" + (onglet === 3 ? "primary" : "outline-secondary")} onClick={() => handleChoice(3)}>PRIVILEGES</button>
                            <button type="button" className={"btn btn-lg btn-" + (onglet === 4 ? "primary" : "outline-secondary")} onClick={() => handleChoice(4)}>{t("settings.onglet4")}</button>
                        </>
                    }

                    <button type="button" className={"btn btn-lg btn-" + (onglet === 6 ? "primary" : "outline-secondary")} onClick={() => handleChoice(6)}>{t("settings.onglet6")}</button>
                </div>

                {(onglet === 1) &&
                    <SettingsUser user={props.user} />
                }

                {(onglet === 2) &&
                    <div className="row">

                        {(userHasPriv(props.user, liste_priv.digibobo.ordi)) &&
                            <>
                                <button className="p-3 m-2 col-auto btn btn-lg btn-info d-flex fs-4"><a className="link-underline link-underline-opacity-0 text-dark" href="/ordi_admin">{t("settings.btn_ordi")}</a></button>
                                <button className="p-3 m-2 col-auto btn btn-lg btn-info d-flex fs-4"><a className="link-underline link-underline-opacity-0 text-dark" href="/composante_admin/">{t("settings.btn_composante")}</a></button>
                                <button className="p-3 m-2 col-auto btn btn-lg btn-info d-flex fs-4"><a className="link-underline link-underline-opacity-0 text-dark" href="/processeur_admin/">{t("settings.btn_processeurs")}</a></button>
                            </>
                        }

                        {(userHasPriv(props.user, liste_priv.digibobo.shop)) &&
                            <button className="p-3 m-2 col-auto btn btn-lg btn-secondary d-flex fs-4"><a className="link-underline link-underline-opacity-0 text-light" href="/order_admin/">{t("settings.btn_orders")}</a></button>
                        }
                        {(userHasPriv(props.user, liste_priv.digibobo.shop)) &&
                            <button className="p-3 m-2 col-auto btn btn-lg btn-secondary d-flex fs-4"><a className="link-underline link-underline-opacity-0 text-light" href="/shop_admin/">{t("settings.btn_shop")}</a></button>
                        }
                        {(userHasPriv(props.user, liste_priv.digibobo.posts)) &&
                            <button className="p-3 m-2 col-auto btn btn-lg btn-secondary d-flex fs-4"><a className="link-underline link-underline-opacity-0 text-light" href="/post_admin/">{t("settings.btn_posts")}</a></button>
                        }
                    </div>
                }

                {(onglet === 3) &&
                    <SettingsPriv user={props.user} />
                }

                {(onglet === 4) &&
                    <SettingsDossier user={props.user} />
                }

                {(onglet === 6) &&
                    <SettingsBigcode user={props.user} />
                }


            </div>
        }
    </div>
}

export default SettingsPage;