import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OrdiForm from "./OrdiForm";
import Header from "../Header";
import { MyScrollButton, digitGroup } from "../Utils";
import PaginatedItems from "../Paginator";
import { MyInputSearch } from "../FormElements";
const proxyUrl = require("../../../package.json").proxy;

const OrdiAdmin = function (props) {

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [ordis, setOrdis] = useState([]);
    const [ordisFilt, setOrdisFilt] = useState([]);
    const [ordi_selected, setOrdi_selected] = useState(null)

    const [ordiProc, setOrdiProc] = useState([]);

    const [ordi_id, setOrdi_id] = useState(0); // pour savoir quel Ordi supprimer

    const [formOpen, setFormOpen] = useState(false);

    const [refreshList, setRefreshList] = useState(false);

    const toggleForm = () => {
        if (formOpen) setOrdi_selected(null);
        setFormOpen(!formOpen);
    }

    const refresh_list = () => {
        
        setRefreshList(!refreshList);
        setFormOpen(false)
        setOrdi_selected(null)
    }

    const selectOrdi = (comp) => {

        setConfirmDelete(false)
        setOrdi_selected(comp);
        toggleForm();
    }

    // fetch Ordis
    useEffect(() => {

        const fetchOrdis = async () => {
            try {
                const response = await fetch(proxyUrl + '/ordis');
                const data = await response.json();
                setOrdis(data);
                setOrdisFilt(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchOrdis();

    }, [refreshList])

    // fetch Proc types
    useEffect(() => {

        const fetchOrdiProc = async () => {
            try {
                const response = await fetch(proxyUrl + '/proc');
                const data = await response.json();
                setOrdiProc(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchOrdiProc();

    }, [])


    const deleteOrdi = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/ordi', {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({ idOrdi: ordi_id })
                });

                if (response.status === 201) window.location.reload();

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmDelete(false)
    }

    const deleteOrdi_onclick = (name, id) => {
        setOrdi_id(id)
        setConfirmDelete(!confirmDelete)
    }

    const LISTE_TYPE_ORDI = [
        "Desktop",
        "Laptop",
        "All-in-one",
    ];

    const LISTE_RAM = [
        "DDR 1",
        "DDR 2",
        "DDR 3",
        "DDR 4",
    ];

    const RenderOrdis = () => {

        const [currentItems, setCurrentItems] = useState([]);

        const table_titles = [
            "id",
            "A",
            t("ordi.type"),
            t("ordi.denomination"),
            t("ordi.procType"),
            t("ordi.ramType"),
            t("ordi.graph"),
            t("ordi.ecran"),
            t("ordi.prix"),
            "Options"
        ]

        return <>
            <PaginatedItems itemsPerPage={10} items={ordisFilt} setCurrentItems={setCurrentItems} />
            <p>nb: {currentItems.length + " / " + ordisFilt.length}</p>

            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((ordi_elt) => (
                        <tr key={ordi_elt.idOrdi}>
                            <th scope="row">{ordi_elt.idOrdi}</th>
                            <td>{ordi_elt.active ? <span style={{ color: "green" }}>ON</span> : <span style={{ color: "brown" }}>Off</span>}</td>
                            <td>{LISTE_TYPE_ORDI[ordi_elt.typeOrdi - 1]}</td>
                            <td>{ordi_elt.denomination}</td>
                            <td style={{ lineHeight: "0.7em" }}>
                                <p><small>{ordiProc[ordi_elt.idProc - 1]?.procName}</small></p>
                                <p><small>Gen: {ordi_elt.processeurGeneration}</small></p>
                            </td>
                            <td>{LISTE_RAM[ordi_elt.typeram - 1]}</td>
                            <td style={{ lineHeight: "0.7em" }}>
                                <p><small>{ordi_elt.graphMarque ?? "-"}</small></p>
                                <p><small>{t("ordi.graphDedie") + " " + ordi_elt.graphDedie + " Mo"}</small></p>
                                <p><small>{t("ordi.graphTotale") + " " + ordi_elt.graphTotale + " Mo"}</small></p>
                            </td>
                            <td style={{ lineHeight: "0.7em" }}>
                                <p><small>{ordi_elt.ecranTaille}''</small></p>
                                <p><small>{ordi_elt.ecranTactile ? '✔️' : '❌'}Tactile</small></p>
                            </td>
                            <td>{digitGroup(ordi_elt.price)}</td>
                            <td>
                                <button className="btn" onClick={() => selectOrdi(ordi_elt)}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                                </button>
                                <button className="btn" onClick={() => deleteOrdi_onclick(ordi_elt.denomination, ordi_elt.idOrdi)}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
                                </button>
                                {(confirmDelete && (ordi_elt.idOrdi === ordi_id)) && <button className="btn btn-danger fs-5" type="button" onClick={deleteOrdi}>Confirm Delete</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    if (!props.user.email) return <Header user={props.user} />

    return <div>

        <Header user={props.user} />

        <div className="m-3 fs-4">

            <a className="my-3 fs-4 btn btn-lg btn-outline-secondary align-middle" href="/settings">
                {t("settings.back")}
            </a>

            <h1>{t("ordi.titre")}</h1>

            <button className={"fs-4 btn mb-3 btn-" + (ordi_selected === null ? (formOpen ? "outline-info" : "info") : "danger")} onClick={() => toggleForm()}>
                {ordi_selected === null ? t("ordi.btn_add") : t("ordi.btn_cancel_modify")}
            </button>

            {formOpen && <OrdiForm ordi={ordi_selected} refresh_list={refresh_list} LISTE_PROC_TYPE={ordiProc.map((proc) => [proc.idProc, proc.procName])} />}

            <MyInputSearch
                myLabel="Rechercher 1 ordinateur"
                idGroupe={100}
                items={ordis}
                setItemsFiltered={setOrdisFilt}
                filt1="idOrdi"
                filt2="denomination"
            />

            <RenderOrdis />

            <MyScrollButton />

        </div>
    </div>
}

export default OrdiAdmin;