import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ComposanteForm from "./ComposanteForm";
import { digitGroup } from "../Utils";
import { GetListe_Categories } from "../Utils";
import Header from "../Header";
import PaginatedItems from "../Paginator";
import { MyInputSearch } from "../FormElements";
const proxyUrl = require("../../../package.json").proxy;

const ComposanteAdmin = function (props) {

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [composantes, setComposantes] = useState([])
    const [composantesFilt, setComposantesFilt] = useState([])
    const [composante_selected, setComposante_selected] = useState(null)

    const [comp_id, setComp_id] = useState(0); // pour savoir quelle composante supprimer

    const [formOpen, setFormOpen] = useState(false);
    
    const [refreshList, setRefreshList] = useState(false);

    const toggleForm = () => {
        if (formOpen) setComposante_selected(null)
        setFormOpen(!formOpen);
    }

    const refresh_list = () => {
        setRefreshList(!refreshList);
        setFormOpen(false)
        setComposante_selected(null)
    }

    const selectComposante = (comp) => {

        setConfirmDelete(false)
        setComposante_selected(comp);
        setFormOpen(true);
    }

    // fetch Composantes
    useEffect(() => {

        const fetchComposantes = async () => {
            try {
                const response = await fetch(proxyUrl + '/composantes');
                const data = await response.json();
                setComposantes(data);
                setComposantesFilt(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchComposantes();

    }, [refreshList])

    const deleteComposante = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/composante', {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({ idComposante: comp_id })
                });

                if (response.status === 201) window.location.reload();

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmDelete(false)
    }

    const deleteComposante_onclick = (name, id) => {
        setComp_id(id)
        setConfirmDelete(!confirmDelete)
    }

    const RenderComposantes = () => {

        const [currentItems, setCurrentItems] = useState([]);

        const liste_categories = GetListe_Categories()

        const table_titles = [
            "id",
            "A",
            t("composante.category"),
            t("composante.denomination"),
            t("composante.ecranTaille"),
            t("composante.memory"),
            t("composante.graphdedie"),
            t("composante.graphtotale"),
            t("composante.price"),
            t("composante.for_tableau"),
            "Options"
        ]

        return <>
            <PaginatedItems itemsPerPage={10} items={composantesFilt} setCurrentItems={setCurrentItems} />
            <p>nb: {currentItems.length + " / " + composantesFilt.length}</p>

            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((comp_elt) => (
                        <tr key={comp_elt.idComposante}>
                            <th scope="row">{comp_elt.idComposante}</th>
                            <td>{comp_elt.active ? <span style={{ color: "green" }}>ON</span> : <span style={{ color: "brown" }}>Off</span>}</td>
                            <td>{liste_categories[comp_elt.category - 1]?.[1]}</td>
                            <td>{comp_elt.denomination}</td>
                            <td>{comp_elt.ecranTaille}''</td>
                            <td>{comp_elt.memory}</td>
                            <td>{comp_elt.graphDedie}</td>
                            <td>{comp_elt.graphTotale}</td>
                            <td>{digitGroup(comp_elt.price)}</td>
                            <td style={{ lineHeight: "0.7em" }}>
                                <p><small>{comp_elt.forLaptop ? '✔️' : '❌'}Laptop</small></p>
                                <p><small>{comp_elt.forDesktop ? '✔️' : '❌'}Desktop</small></p>
                                <p><small>{comp_elt.forAllInOne ? '✔️' : '❌'}All-in-one</small></p>
                            </td>
                            <td>
                                <button className="btn" onClick={() => {
                                    selectComposante(comp_elt)
                                    window.scrollTo(0, 0)
                                }}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                                </button>
                                <button className="btn" onClick={() => deleteComposante_onclick(comp_elt.denomination, comp_elt.idComposante)}>
                                    <img style={{ height: "35px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
                                </button>
                                {(confirmDelete && (comp_elt.idComposante === comp_id)) && <button className="btn btn-danger fs-5" type="button" onClick={deleteComposante}>Confirm Delete</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    if (!props.user.email) return <Header user={props.user} />

    return <div>

        <Header user={props.user} />

        <div className="body fs-5">

            <a className="my-3 fs-4 btn btn-lg btn-outline-secondary align-middle" href="/settings">
                {t("settings.back")}
            </a>

            <h1>{t("composante.titre")}</h1>

            <button className={"btn btn-lg fs-4 mb-3 btn-" + (composante_selected === null ? (formOpen ? "outline-info" : "info") : "danger")} onClick={() => toggleForm()}>
                {composante_selected === null ? t("composante.btn_add") : t("composante.btn_cancel_modify")}
            </button>

            {formOpen && <ComposanteForm composante={composante_selected} refresh_list={refresh_list} />}

            <MyInputSearch
                myLabel="Rechercher une Composante"
                idGroupe={100}
                items={composantes}
                setItemsFiltered={setComposantesFilt}
                filt1="idComposante"
                filt2="denomination"
            />

            <RenderComposantes />
        </div>
    </div>
}

export default ComposanteAdmin;