import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyImage, MyImagePreview, MyInput, MyTextArea } from '../FormElements';
import { displaySnackbar, rangDossiers, slang } from "../Utils"

const proxyUrl = require("../../../package.json").proxy;

function ShopForm(props) {

    const { t } = useTranslation();

    const sp = props.sp;
    const bool_modify = Boolean(sp);

    const [spToSave, setSpTosave] = useState({
        idShopProduct: bool_modify ? sp.idShopProduct : 0,
        denomination: bool_modify ? sp.denomination : '',
        denominationEN: bool_modify ? sp.denominationEN : '',
        description: bool_modify ? sp.description : '',
        descriptionEN: bool_modify ? sp.descriptionEN : '',
        price: bool_modify ? sp.price : 0,
        active: bool_modify ? sp.active : true,
    })

    const [image, setImage] = useState(null);

    // Modification
    const handleModify = async () => {

        let url = '';

        // Modification éventuelle de l'image
        if (image) {
            url = proxyUrl + "/create_image"

            const formData = new FormData();

            formData.append('idElement', spToSave.idShopProduct)
            formData.append('rangDossier', rangDossiers.shop_product)
            formData.append('idData', 0)
            formData.append('myImage', image)

            // Logique pour enregistrer ici

            fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "multipart/form-data",
                },
                credentials: "include",
                body: formData,
            })
        }

        // Modification de l'élément lui même
        url = proxyUrl + "/shop_product/" + sp.idShopProduct;

        const response = await fetch(url, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify(spToSave),
        })

        if (response.status === 201) {
            props.refresh_list()
            displaySnackbar(slang("Modification effectuée","Saved succesfully"),"OK","success")
        }
    }

    // Création
    const handleCreation = async () => {

        const url = proxyUrl + "/shop_product"
        const formData = new FormData();

        if (!image){
            displaySnackbar(
                slang("Veuillez sélectionner une image svp","Please an image is required"), 
                slang("Select an image","Choose an image"), 
                "warning")
            return
        }

        formData.append('denomination', spToSave.denomination)
        formData.append('denominationEN', spToSave.denominationEN)
        formData.append('description', spToSave.description)
        formData.append('descriptionEN', spToSave.descriptionEN)
        formData.append('price', spToSave.price)
        formData.append('active', spToSave.active)
        formData.append('myImage', image)

        // Logique pour enregistrer ici

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Accept": "multipart/form-data",
            },
            body: formData,
            credentials: "include",
        })

        if (response.status === 201) window.location.reload();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (bool_modify) {
            handleModify();

        } else {
            handleCreation();
        }
    };

    const refresh_spToSave = function (myName, myValue) {

        const newComp = { ...spToSave, [myName]: myValue }

        setSpTosave(newComp);
    }

    return (
        <div className="col-lg-6 col-md-11 mx-auto">
            {bool_modify ?
                <h2>{t("shop.modify") + " " + sp.denomination}</h2>
                :
                <h2>{t("shop.create")}</h2>
            }

            <form className="border-bottom border-secondary mb-3" onSubmit={handleSubmit}>
                <MyInput
                    myName="denomination"
                    refresh_itemToSave={refresh_spToSave}
                    idGroupe={1}
                    myLabel={t("shop.form1")}
                    myType="text"
                />

                <MyInput
                    myName="denominationEN"
                    refresh_itemToSave={refresh_spToSave}
                    idGroupe={2}
                    myLabel={t("shop.form2")}
                    myType="text"
                />

                <MyTextArea
                    myName="description"
                    refresh_itemToSave={refresh_spToSave}
                    idGroupe={3}
                    myLabel={t("shop.form3")}
                    myType="text"
                />

                <MyTextArea
                    myName="descriptionEN"
                    refresh_itemToSave={refresh_spToSave}
                    idGroupe={4}
                    myLabel={t("shop.form4")}
                    myType="text"
                />
                
                <div className="row">

                    <MyInput
                        myName="price"
                        refresh_itemToSave={refresh_spToSave}
                        idGroupe={5}
                        myLabel={t("shop.form5")}
                        myType="number"
                    />

                    <MyImage
                        myLabel="image"
                        setImage={setImage}
                    />

                </div>

                <MyImagePreview
                    image={image}
                    id_image={sp?.idShopProduct}
                    bln_modify={bool_modify}
                    base_url={proxyUrl + "/uploads/0/id_image-" + rangDossiers.shop_product + ".jpg"}
                />

                <button className="btn btn-lg fs-4 btn-primary" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>
            </form>
        </div>
    );
}

export default ShopForm;