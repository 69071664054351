
import { useEffect, useState } from "react";
import Header from "../Header";
import { Line, Pie } from "react-chartjs-2";
import ActivityFilter, { modeStat } from "./ActivityFilter";

// eslint-disable-next-line
import Chart from 'chart.js/auto'; // cette ligne permet d'enregistrer directement les composants utilisés ex: LineElement
import { MyScrollButton, MySpinner, digitGroup, format_date, jump, liste_priv, slang, userHasPriv } from "../Utils";
import PaginatedItems from "../Paginator";
import { MyInputSearch } from "../FormElements";
import { UserAccessExpired } from "../AccesDenied";
import { useTranslation } from "react-i18next";

Chart.defaults.font.size = 18;

const proxyUrl = require("../../../package.json").proxy;

const ActivityPage = function (props) {

    const { t } = useTranslation()

    const [showFilter, setShowFilter] = useState(true)
    const [showSpinner, setShowSpinner] = useState(false)

    const [modeActuel, setModeActuel] = useState(0) // 1:ventes 2:benefice 3:tickets
    const [groupeDates, setGroupeDates] = useState(2) // 1:jour 2:semaines 3:années
    const [categorie, setCategory] = useState(1) // 1:globales 2:groupées par famille 3:groupées par activités
    const [typeGraph, setTypeGraph] = useState(1) // 1:courbe 2:camembert

    const [data1, setData1] = useState(null)
    const [data2, setData2] = useState(null)
    const [data_labels, setData_labels] = useState([])
    const [data_labels3, setData_labels3] = useState([])
    const [datasets1, setDatasets1] = useState([]) // for line chart (simple or with benef)
    const [datasets2, setDatasets2] = useState([]) // for line chart (fam or act)
    const [datasets3, setDatasets3] = useState([]) // for pie chart

    const [tickets, setTickets] = useState([])
    const [ticketsFiltered, setTicketsFiltered] = useState([])
    const [tickets_suppr, setTickets_suppr] = useState([])
    const [tickets_supprFiltered, setTickets_supprFiltered] = useState([])

    const tomorrow = (new Date(Date.now() + 86400000))

    const [current_selection, setCurrent_Selection] = useState({
        idDossierClient: 0,
        dateDebut: (new Date()).toISOString().split('T')[0],
        dateFin: tomorrow.toISOString().split('T')[0],
        idData: [],
    })


    // Fetch Data to display
    const fetchData = async function (justTicket) {

        try {

            const getData = async (url) => {

                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({ ...current_selection })
                });

                return response.json()
            }


            setShowSpinner(true)

            if (justTicket) {
                if (modeActuel === modeStat.tickets) {
                    const tk_data = await getData(proxyUrl + "/tickets");

                    setTickets(tk_data);
                    setTicketsFiltered(tk_data);
                } else {
                    const tk_data_suppr = await getData(proxyUrl + "/tickets_suppr");

                    setTickets_suppr(tk_data_suppr);
                    setTickets_supprFiltered(tk_data_suppr);
                }
            } else {

                setData1(await getData(proxyUrl + "/ventes"));
                setData2(await getData(proxyUrl + "/ventes_fam_act"));
            }


            setShowSpinner(false)

        } catch (error) {
            console.error(error);
        }
    }

    // Charge les données de VENTES GLOBALES pour le graphique

    useEffect(() => {

        let my_data_labels = []
        let my_data_values = []
        let my_data_pa = []
        let my_data_benef = []
        let my_datasets = []

        if (!data1) return

        let dataSource = data1


        for (const [key, value] of Object.entries([dataSource.jours, dataSource.semaines, dataSource.mois][groupeDates - 1])) {

            my_data_labels.push(key)
            my_data_values.push(value[0])
            my_data_pa.push(Math.abs(value[1]))
            my_data_benef.push(value[0] + value[1])
        }

        switch (modeActuel) {
            case modeStat.ventes:
                my_datasets = [{
                    label: 'Sales',
                    data: my_data_values,
                    borderWidth: 2,
                    borderColor: 'rgb(142, 163, 200)',
                }]
                break;
            case modeStat.benef:
                my_datasets = [{
                    label: 'Sales',
                    data: my_data_values,
                    borderWidth: 2,
                    borderColor: 'rgb(142, 163, 200)',
                }, {
                    label: 'Cost',
                    data: my_data_pa,
                    borderWidth: 2,
                    borderColor: 'rgb(250, 167, 165)',
                }, {
                    label: 'Balance',
                    data: my_data_benef,
                    borderWidth: 2,
                    borderColor: 'rgb(167, 230, 165)',
                }]
                break;

            default:
                break;
        }

        setData_labels(my_data_labels)
        setDatasets1(my_datasets)

    }, [data1, modeActuel, categorie, groupeDates])

    // Charge les données de VENTES FAMILLES pour le graphique

    useEffect(() => {

        function sortObject(obj) {
            return Object.keys(obj).sort().reduce(function (result, key) {
                result[key] = obj[key];
                return result;
            }, {});
        }

        let my_graph_labels = []
        let my_data_labels = []
        let my_data_values = []
        let my_datasets = []
        let my_data_labels3 = []
        let my_data_values3 = []
        let my_datasets3 = []
        let dataSource = []

        if (!data2) return

        if (categorie === 2) {

            dataSource = data2["fam"]
        } else {

            dataSource = data2["act"]
        }

        for (let i = 0; i < Object.keys(dataSource).length; i++) {
            const graph_key = Object.keys(dataSource)[i]
            const element = dataSource[graph_key];

            let total_element = 0

            my_data_labels[graph_key] = []
            my_data_values[graph_key] = []

            for (const [key, value] of Object.entries([sortObject(element.jours), sortObject(element.semaines), sortObject(element.mois)][groupeDates - 1])) {

                my_graph_labels.push(key)
                my_data_labels[graph_key].push(key)
                my_data_values[graph_key].push({ x: key, y: value })
                total_element += value
            }

            my_data_labels3.push(element.nomSerie)
            my_data_values3.push(total_element)

            my_datasets.push({
                label: element.nomSerie,
                data: my_data_values[graph_key],
                borderWidth: 2,
            })

        }

        const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

        const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;

        my_datasets3.push({
            label: 'Sales',
            data: my_data_values3,
            backgroundColor: my_data_labels3.map(l => {

                return randomRGB()
            }),

            borderWidth: 1,
        })

        setDatasets2(my_datasets)
        setData_labels3(my_data_labels3)
        setDatasets3(my_datasets3)

    }, [data2, modeActuel, categorie, groupeDates])

    const handleChoice = function (choice) {

        if (modeActuel === modeStat.tickets || modeActuel === modeStat.tickets_suppr) {

            if (modeActuel === modeStat.benef || modeActuel === modeStat.ventes) {
                setShowFilter(true)
            }
        } else {
            if (choice === modeStat.tickets || choice === modeStat.tickets_suppr) {
                setShowFilter(true)
            }
        }
        setModeActuel(choice);
    };



    // Liste des Tickets
    const ListeTickets = function () {

        const [currentItems, setCurrentItems] = useState([]);
        let baseItems = []

        const TicketRows = function ({ content: [tk, ct] }) {

            if (!tk) return <></>

            let statut = ""

            if (tk.reglement) statut += slang(" Payé", " Settled")

            if (modeActuel === modeStat.tickets_suppr) statut += slang(" Supprimé", " Deleted")

            return <div className="row">

                <div className="card m-3 p-0 col-lg-10 col-12 mx-auto">

                    <div className="card-header">
                        <p className="card_title display-6">{"Ticket " + tk["IdTicket"]} - {"Total " + tk["Total"] + statut} </p>
                        <p>{format_date(Date.parse(tk.DateCreation.toString()))} - {"Serveur " + tk["IdServeur"]} - {"Tarification " + tk["IdTarification"]}</p>
                    </div>
                    <div className="card-body">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Qte</th>
                                    <th scope="col">Libelle</th>
                                    <th className="text-end" scope="col">PU</th>
                                    <th className="text-end" scope="col">Remise</th>
                                    <th className="text-end" scope="col">PT</th>
                                </tr>
                            </thead>
                            <tbody>

                                {ct.map((row, i) => {

                                    const if_ligne_a_suivre_or_compo_pack = (elt) => {
                                        if (row.TypeTicketLigne === 1 || (row.TypeTicketLigne === 11 || row.TypeTicketLigne === 12)) return ""
                                        return elt
                                    }


                                    return <tr key={i}>
                                        <td>{if_ligne_a_suivre_or_compo_pack(row.Qte)}</td>
                                        <td>{row.Libelle}</td>
                                        <td className="text-end text-nowrap">{if_ligne_a_suivre_or_compo_pack(digitGroup(row.PU))}</td>
                                        <td className="text-end text-nowrap">{if_ligne_a_suivre_or_compo_pack(digitGroup((-1 * row.PU * row.Qte) + row.PT))}</td>
                                        <td className="text-end text-nowrap">{if_ligne_a_suivre_or_compo_pack(digitGroup(row.PT))}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>

                        <button className="btn btn-outline-success" onClick={() => {

                            setTkRows([])

                        }}>Close</button>
                    </div>

                </div>
            </div>
        }

        const [tk_rows, setTkRows] = useState([])

        const display_Ticket = async function (idTicket, idData) {

            let bool_tkfound = false

            const fetchTkRows = async () => {
                try {

                    let url = ""

                    if (modeActuel === modeStat.tickets) {

                        url = proxyUrl + '/ticket/' + idTicket + '/' + idData

                    } else if (modeActuel === modeStat.tickets_suppr) {

                        url = proxyUrl + '/ticket_suppr/' + idTicket + '/' + idData

                    }

                    const response = await fetch(url);
                    if (response.status === 200) {

                        const data = await response.json();
                        setTkRows(data)
                        bool_tkfound = true
                    }

                } catch (error) {
                    console.error(error);
                }
            };

            await fetchTkRows();

            if (bool_tkfound) jump("formDisplayTicket")
        }

        const ListeOptions = function ({ id, idData }) {

            return <td>
                <button className="btn" onClick={() => display_Ticket(id, idData)}>
                    <img style={{ height: "30px", width: "auto" }} src="/images/select.svg" alt="select" />
                </button>
            </td>
        }

        if (current_selection.idDossierClient === 0) return <></>

        if (modeActuel === modeStat.tickets) {

            baseItems = ticketsFiltered

        } else if (modeActuel === modeStat.tickets_suppr) {

            baseItems = tickets_supprFiltered

        } else {
            return <></>
        }

        return <>
            <TicketRows content={tk_rows} />
            <PaginatedItems itemsPerPage={50} items={baseItems} setCurrentItems={setCurrentItems} />
            <p>nb: {currentItems.length + " / " + baseItems.length}</p>
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        {[
                            "id",
                            "Date",
                            t("activity.amount"),
                            "Data",
                            "Options"
                        ].map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((tk_elt) => {
                        return <tr key={tk_elt.IdTicket} id={"tk" + tk_elt.IdTicket}>
                            <th scope="row">{tk_elt.IdTicket}</th>
                            <td>{format_date(Date.parse(tk_elt.DateCreation))}</td>
                            <td><div className="col-8 text-end text-nowrap">{digitGroup(tk_elt.Total)}</div></td>
                            <td>{tk_elt.idData}</td>

                            <ListeOptions id={tk_elt.IdTicket} idData={tk_elt.idData} />
                        </tr>
                    })}
                </tbody>
            </table>
        </>
    }

    // Bouton Afficher le filtre
    const BtnShowFilter = function () {

        return <button type="button" className="btn btn-lg btn-info col-2 my-3" onClick={() => {
            setShowFilter(!showFilter)
        }}>
            {t("activity.showfilter")}
        </button>
    }

    return <div>
        <Header user={props.user} />

        {((new Date(props.user.dateFin)) < Date.now()) ?
            <UserAccessExpired user={props.user} />
            :
            <div className="col mx-auto px-3 my-3" style={{ maxWidth: "1200px" }}>

                <h2 className="mt-6">{t("activity.title")}</h2>

                {/* ================= */}

                {/*     CHOICE       */}

                {/* ================= */}

                <div className="row">

                    <div className="btn-group mt-3 mb-3 col-lg-8 col-md-10 col-sm-12" role="group" aria-label="Navigation buttons">
                        <button
                            type="button"
                            className={"btn btn-lg fs-4 btn-" + (modeActuel === modeStat.ventes ? "primary" : "outline-dark")}
                            onClick={() => handleChoice(modeStat.ventes)}>
                            {t("activity.onglet1")}
                        </button>

                        {(userHasPriv(props.user, liste_priv.dossier.stats_benef, current_selection.idDossierClient, 0) && (categorie === 1)) &&
                            <button
                                type="button"
                                className={"btn btn-lg fs-4 btn-" + (modeActuel === modeStat.benef ? "primary" : "outline-dark")}
                                onClick={() => handleChoice(modeStat.benef)}>
                                {t("activity.onglet2")}
                            </button>
                        }

                        <button
                            type="button"
                            className={"btn btn-lg fs-4 btn-" + (modeActuel === modeStat.tickets ? "primary" : "outline-dark")}
                            onClick={() => handleChoice(modeStat.tickets)}>
                            TICKETS
                        </button>

                        <button
                            type="button"
                            className={"btn btn-lg fs-4 btn-" + (modeActuel === modeStat.tickets_suppr ? "primary" : "outline-dark")}
                            onClick={() => handleChoice(modeStat.tickets_suppr)}>
                            {t("activity.onglet4").toUpperCase()}
                        </button>

                    </div>
                </div>

                {/* ================= */}

                {/* VENTES - BENEFICE */}

                {/* ================= */}



                {/* FILTRE VENTE/BENEF */}

                {showFilter &&
                    <ActivityFilter
                        current_selection={current_selection}
                        setCurrent_Selection={setCurrent_Selection}
                        modeActuel={modeActuel}
                        user={props.user}
                        setShowFilter={setShowFilter}
                        fetchData={fetchData}
                    />}

                <MySpinner show={showSpinner} myText={t("general.loading") + " Data"} />

                {((modeActuel === modeStat.ventes) || (modeActuel === modeStat.benef)) &&
                    <>
                        {/* BOUTONS GERANT L'AFFICHAGE DU GRAPHIQUE */}

                        {!showFilter && <div className="col-12">
                            {!showSpinner &&

                                <div className="row">
                                    <div className="btn-group mt-3 mb-3 col-lg-4 col-md-5 col-sm-6 col-12 mx-auto" role="group" aria-label="Filter buttons">
                                        <button type="button" className={"btn btn-lg btn-" + (categorie === 1 ? "secondary" : "outline-dark")} onClick={() => { setCategory(1) }}>Globales</button>
                                        <button type="button" className={"btn btn-lg btn-" + (categorie === 2 ? "secondary" : "outline-dark")} onClick={() => {
                                            setCategory(2)
                                            setModeActuel(1)
                                        }}>Familles</button>
                                        <button type="button" className={"btn btn-lg btn-" + (categorie === 3 ? "secondary" : "outline-dark")} onClick={() => {
                                            setCategory(3)
                                            setModeActuel(1)
                                        }}>Activites</button>
                                    </div>

                                    <div className="btn-group mt-3 mb-3 col-lg-4 col-md-5 col-sm-6 col-12 mx-auto" role="group" aria-label="Filter buttons">
                                        <button type="button" className={"btn btn-lg btn-" + (groupeDates === 1 ? "secondary" : "outline-dark")} onClick={() => setGroupeDates(1)}>Jours</button>
                                        <button type="button" className={"btn btn-lg btn-" + (groupeDates === 2 ? "secondary" : "outline-dark")} onClick={() => setGroupeDates(2)}>Semaines</button>
                                        <button type="button" className={"btn btn-lg btn-" + (groupeDates === 3 ? "secondary" : "outline-dark")} onClick={() => setGroupeDates(3)}>Mois</button>
                                    </div>

                                    {categorie > 1 &&
                                        <div className="btn-group mt-3 mb-3 col-lg-4 col-md-5 col-sm-6 col-12 mx-auto" role="group" aria-label="Filter buttons">
                                            <button type="button" className={"btn btn-lg btn-" + (typeGraph === 1 ? "secondary" : "outline-dark")} onClick={() => setTypeGraph(1)}>Courbe</button>
                                            <button type="button" className={"btn btn-lg btn-" + (typeGraph === 2 ? "secondary" : "outline-dark")} onClick={() => setTypeGraph(2)}>Camembert</button>
                                        </div>
                                    }
                                </div>
                            }

                            {/* GRAPHIQUES */}

                            {!showSpinner && <div className="row vh-75">

                                {(categorie === 1) &&
                                    <div className="mx-auto col-10 col-md-12">

                                        < Line
                                            data={{
                                                labels: data_labels,
                                                datasets: datasets1,
                                            }}
                                        />
                                    </div>
                                }

                                {(categorie > 1) &&
                                    <>
                                        {(typeGraph === 1) &&

                                            <div className="mx-auto col-10 col-md-12">
                                                < Line
                                                    data={{
                                                        datasets: datasets2,
                                                    }}
                                                />
                                            </div>
                                        }

                                        {(typeGraph === 2) &&
                                            <div className="mx-auto col-8 col-md-11">

                                                < Pie
                                                    data={{
                                                        labels: data_labels3,
                                                        datasets: datasets3,
                                                    }}
                                                />
                                            </div>
                                        }
                                    </>
                                }

                            </div>
                            }
                        </div>}
                    </>
                }

                {/* ================= */}

                {/* TICKETS */}

                {/* ================= */}

                {((modeActuel === modeStat.tickets) && !showSpinner) &&

                    <div className="col-11 mx-auto">

                        {!showFilter &&
                            <div className="row">

                                <div id="formDisplayTicket"></div>

                                <div className="col-lg-6 col-md-9 col-sm-12">

                                    <MyInputSearch
                                        myLabel="Tickets"
                                        idGroupe={100}
                                        items={tickets}
                                        setItemsFiltered={setTicketsFiltered}
                                        filt1="IdTicket"
                                        filt2="Total"
                                    />

                                </div>

                                <div className="col-12">

                                    {!showFilter && <BtnShowFilter />}
                                </div>


                                <ListeTickets />

                            </div>
                        }

                    </div>
                }

                {/* ================= */}

                {/* TICKETS SUPPRIMES */}

                {/* ================= */}

                {((modeActuel === modeStat.tickets_suppr) && !showSpinner) &&

                    <div className="col-11 mx-auto">

                        {!showFilter &&
                            <div className="row">

                                <div id="formDisplayTicket"></div>

                                <div className="col-md-5 col-sm-12">

                                    <div className="input-group mb-1 fs-5">
                                        <MyInputSearch
                                            myLabel={t("activity.onglet4")}
                                            idGroupe={100}
                                            items={tickets_suppr}
                                            setItemsFiltered={setTickets_supprFiltered}
                                            filt1="IdTicket"
                                            filt2="Total"
                                        />
                                    </div>

                                </div>

                                <ListeTickets />

                            </div>
                        }

                    </div>
                }

                {!showFilter && <BtnShowFilter />}

                <p className="fs-5 text-center text-secondary mt-1">Filter : {JSON.stringify(current_selection, null, " ").replace(/"/g, '')}</p>

            </div>
        }

        {((modeActuel === modeStat.tickets || modeActuel === modeStat.tickets_suppr) && !showFilter) &&
            <MyScrollButton />
        }

    </div >
}

export default ActivityPage;