
import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Header from "../Header";
import Footer from "../Footer";
import { Article } from "./ShopArticle";

const proxyUrl = require("../../../package.json").proxy;

const ShopMain = function (props) {

    const { t } = useTranslation()

    const [shopProducts, setShopProducts] = useState([]);

    const [searchProduct,
        setSearchProduct] = useState("");

    const products = shopProducts.reduce(function (filtered, product) {

        if (searchProduct.length > 0) {
            if (product.denomination.toLowerCase().includes(searchProduct.toLowerCase()) || product.denominationEN.toLowerCase().includes(searchProduct.toLowerCase())) {

                filtered.push(product);
            }
        } else {
            filtered.push(product);
        }

        return filtered
    }, []);

    // fetch ShopProducts
    useEffect(() => {

        const fetchShopProducts = async () => {
            try {
                const response = await fetch(proxyUrl + '/shop_products');
                const data = await response.json();
                setShopProducts(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchShopProducts();

    }, [])

    return <div>
        <Header user={props.user} />

        <div className="body fs-5 p-3">

            <h1>{t("home.shop_titre")}</h1>

            <input
                id="search_shop_product"
                className="form-control fs-4 border-primary"
                name="search_shop_product"
                placeholder={t("shop.search_placeholder")}
                onChange={(e) => {
                    setSearchProduct(e.target.value)
                }}
            />

            {/* Affiche le nombre de résultats */}

            <h4 className="m-3 fs-4">
                {products.length + " "}

                <Trans i18nKey="ordi.results" count={products.length}>
                    section_texte_simple_id0
                </Trans>
            </h4>

            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 pb-3 g-4">

                {
                    products.map((sp, idx) => {
                        return <Article key={idx} sp={sp} />
                    })
                }

                <Article key={0} sp={{
                    idShopProduct: 0,
                    denomination: "Logiciel de caisse Breebar",
                    denominationEN: "Breebar cash management software",
                    description: <div>

                        Profitez de l'expérience de dizaines d'utilisateurs convaincus et Rejoignez le mouvement dès maintenant !
                        &nbsp;
                        <a className="btn btn-outline-primary" href="/breebar_func">{t("shop.know_more")}</a>

                    </div>,
                    descriptionEN: <div>

                        Take advantage of the experience of dozens of convinced users and join the movement now!
                        &nbsp;
                        <a className="btn btn-outline-primary" href="/breebar_func">{t("shop.know_more")}</a>

                    </div>,
                    price: 65000,
                }} />
            </div>

            <p className="fs-4 text-center">
                <Trans i18nKey="ordi.composer_greeting">
                    section_texte_simple_id0<strong>section_id1</strong>section_texte_simple_id2<strong>section_3</strong>section_4<strong>section_6</strong>section_7
                </Trans>
            </p>

        </div>

        <Footer />
    </div>
}

export default ShopMain;