import { useState } from "react";
import { useTranslation } from "react-i18next";
import { slang } from "./Utils";

export function MySelect({ myLabel, myName, refresh_itemToSave, idGroupe, data, func = null }) {

    // data : 0=id 1=text 2=defaultvalue

    const { t } = useTranslation();

    let default_value_filled = false;

    const getDefaultValue = function () {
        const find_result = data.find(obj => obj[2] === true)

        return (find_result) ? find_result[0] : "none";
    }

    return <div className="form-group">
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <label className="input-group-text fs-4" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <select
                id={"inputGroup" + idGroupe}
                className="custom-select fs-4"
                name={myName}
                defaultValue={getDefaultValue()}
                onChange={(e) => {
                    if (func) {

                        refresh_itemToSave(myName, func(e.target.value))
                        
                    } else {

                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            >

                {data.map((elt, idx) => {
                    if (!default_value_filled) {
                        default_value_filled = elt[2];
                    }
                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

                {!default_value_filled && <option value="none" disabled hidden>{t("general.select_default")}</option>}
            </select>
        </div>
    </div>
}

export function MySelectMultiple({ myLabel, myName, refresh_itemToSave, idGroupe, data, func = null }) {

    // data : 0=id 1=text 2=defaultvalue

    const { t } = useTranslation();

    function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                if (func) {

                    result.push(func(opt.value || opt.text));
                } else {

                    result.push(opt.value || opt.text);
                }
            }
        }
        return result;
    }

    return <div className="form-group mb-3">
        <div className="input-group mb-1">
            <label className="input-group-text fs-4" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>

            <select
                id={"inputGroup" + idGroupe}
                className="form-select fs-4"
                name={myName}
                multiple={true}
                onChange={(e) => {
                    refresh_itemToSave(myName, getSelectValues(e.target))
                }}
            >

                {data.map((elt, idx) => {

                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

                <option value="none" disabled hidden>{t("general.select_default")}</option>
            </select>
        </div>
    </div>
}

export function MySelectSearch({ myLabel, myName, refresh_itemToSave, idGroupe, data, func = null }) {

    // data : 0=id 1=text 2=defaultvalue

    const { t } = useTranslation();

    const [dataFilter, setDataFilter] = useState("");
    const [currentValue, setCurrentValue] = useState();

    const compose_data = function () {
        // Permet de respecter le filtre actif

        if (dataFilter.length < 2) return data

        return data.filter((d) => {
            return d[1].toLowerCase().includes(dataFilter.toLowerCase())
        })
    }

    return <div className="form-group mb-3">
        <div className="input-group mb-1">
            <label className="input-group-text fs-4" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>

            <input
                type="text"
                className="form-control fs-4"
                style={{ maxWidth: "100px" }}
                value={dataFilter}
                placeholder={"search"}
                onChange={(e) => {
                    setDataFilter(e.target.value)
                }} />

            <button className="form-control fs-4" style={{ maxWidth: "50px" }} type="button" onClick={(e) => {setDataFilter("")}}>X</button>

            <select
                id={"inputGroup" + idGroupe}
                className="form-select fs-4"
                name={myName}
                value={currentValue}
                onChange={(e) => {

                    e.preventDefault()

                    if (func) {

                        refresh_itemToSave(myName, func(e.target.value))
                    } else {

                        setCurrentValue(e.target.value)
                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            >

                <option value="none">{t("general.select_default")}</option>

                {compose_data().map((elt, idx) => {

                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

            </select>
        </div>

        {(dataFilter.length > 1) && <div className="btn-group mb-3 fs-4" role="group" aria-label="Buttons to select an item">

            {compose_data().map((elt, idx) => {
                return <button className="btn btn-lg fs-4 btn-outline-primary" key={idx} type="button" onClick={(e) => {

                    setDataFilter("")

                    if (func) {

                        setCurrentValue(func(elt[0]))
                        refresh_itemToSave(myName, func(elt[0]))
                    } else {

                        setCurrentValue(elt[0])
                        refresh_itemToSave(myName, elt[0])
                    }
                }}>{elt[1]}</button>
            })}
        </div>}
    </div>
}

export function MyInput({ myLabel, myName, myValue, refresh_itemToSave, idGroupe, myType, step = 1, func = null }) {

    return <div className="form-group">
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <label className="input-group-text fs-4" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <input
                id={"inputGroup" + idGroupe}
                className="form-control fs-4"
                name={myName}
                type={myType}
                step={step}
                defaultValue={myValue}
                onChange={(e) => {
                    if (func) {

                        refresh_itemToSave(myName, func(e.target.value))
                    } else {

                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            />
        </div>
    </div>
}

export function MyTextArea({ myLabel, myName, myValue, refresh_itemToSave, idGroupe, myType }) {

    return <div className="form-group">
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <label className="input-group-text fs-4" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <textarea
                id={"inputGroup" + idGroupe}
                className="form-control fs-4"
                name={myName}
                rows={4}
                defaultValue={myValue}
                onChange={(e) => {
                    refresh_itemToSave(myName, e.target.value)
                }}
            />
        </div>
    </div>
}

export function MyInputSearch({ myLabel, idGroupe, items, setItemsFiltered, filt1, filt2, filt3 }) {

    return <div className="input-group">
        <label className="input-group-text fs-4" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
        <input
            id={"inputGroup" + idGroupe}
            type="text"
            className="form-control fs-4"
            style={{ maxWidth: "150px" }}

            placeholder={slang("recherche","search")}
            onChange={(e) => {

                let filt = [];

                if (e.target.value.length === 0) {

                    filt = items
                } else {

                    filt = items.filter((t) => {

                        let filt_elt = ""

                        if (filt1) filt_elt += t[filt1]
                        if (filt2) filt_elt += t[filt2]
                        if (filt3) filt_elt += t[filt3]

                        return filt_elt.toLowerCase().includes(e.target.value.toLowerCase())
                    })
                }

                setItemsFiltered(filt)
            }} />

        <button 
        className="form-control fs-4" 
        style={{ maxWidth: "50px" }} 
        type="button" 
        onClick={() => { document.getElementById("inputGroup" + idGroupe).value = ""; setItemsFiltered(items) }}>X</button>
    </div>
}

export function MySwitch({ myLabel, myName, refresh_itemToSave, idGroupe }) {
    return <div className="form-check form-switch">

        <label className="form-check-label fs-4" htmlFor={"Switch" + idGroupe}>{myLabel}</label>

        <input className="form-check-input"
            type="checkbox"
            name="Switch"
            role="switch"
            id={"Switch" + idGroupe}
            onChange={(e) => {

                refresh_itemToSave(myName, e.target.checked)
            }}
        />

    </div>
}

export function MyCheckBox({ myLabel, myName, refresh_itemToSave, idGroupe }) {
    return <div className="form-check col">
        <div className="row border-bottom">

            <div className="col-5">
                <label className="font-italic fs-4" htmlFor={"ON" + idGroupe}>{myLabel}</label>
            </div>

            <div className="col-4">

                <input className="form-check-input"
                    type="checkbox"
                    name="ON"
                    onChange={(e) => {
                        document.getElementById("OFF" + idGroupe).checked = false;
                        refresh_itemToSave(myName, true)
                    }}
                    id={"ON" + idGroupe} />
                <label className="form-check-label fs-5" htmlFor={"ON" + idGroupe}>
                    ON
                </label>
            </div>

            <div className="col-3">

                <input className="form-check-input"
                    type="checkbox"
                    name="OFF"
                    onChange={(e) => {
                        document.getElementById("ON" + idGroupe).checked = false;
                        refresh_itemToSave(myName, false)
                    }}
                    id={"OFF" + idGroupe} />
                <label className="form-check-label fs-5" htmlFor={"OFF" + idGroupe}>
                    OFF
                </label>
            </div>
        </div>
    </div>
}

export function MyImage({ myLabel, setImage, idGroupe }) {
    return <div className="input-group">
        <div className="input-group-prepend">
            <label className="input-group-text" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
        </div>
        <input
            id={"inputGroup" + idGroupe}
            type="file"
            accept=".jpg"
            onChange={(e) => setImage(e.target.files[0])}
        />

        <button className="btn btn-lg fs-4 btn-sm btn-danger" type="button" onClick={(e) => { e.preventDefault(); setImage(null) }}>Reset Image</button>
    </div >
}

export function MyImagePreview({ image, base_url, id_image, bln_modify }) {

    // Gestion de l'URL de l'image
    const getImageURL = () => {
        if (!image && bln_modify) {

            return base_url.replace("id_image", id_image)
        }

        return URL.createObjectURL(image)
    }

    return <>
        {(image || bln_modify) &&
            <img style={{ maxHeight: "300px" }} src={getImageURL()} alt="élément / Item" />
        }
    </>
}