import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyInput, MySelect, MyImage, MyImagePreview, MyTextArea } from '../FormElements';
import { displaySnackbar, rangDossiers, slang } from "../Utils"
import { languages } from '../LanguageSelector';

const proxyUrl = require("../../../package.json").proxy;

function PostForm(props) {

    const { t } = useTranslation();

    const post = props.post;
    const bool_modify = Boolean(post);

    const [postToSave, setPostTosave] = useState({
        title: bool_modify ? post.content : '',
        content: bool_modify ? post.content : '',
        language: bool_modify ? post.language : 'fr',
        createdAt: bool_modify ? post.createdAt : Date.now(),
    })

    const [image, setImage] = useState(null);

    // modification du post
    const handleModify = async () => {

        let url = "";

        // Modification éventuelle de l'image
        if (image) {
            url = proxyUrl + "/create_image"

            const formData = new FormData();

            formData.append('idElement', post.idPost)
            formData.append('rangDossier', rangDossiers.post)
            formData.append('idData', 0)
            formData.append('myImage', image)

            // Logique pour enregistrer ici

            fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "multipart/form-data",
                },
                body: formData,
                credentials: "include",
            })
        }

        url = proxyUrl + "/modify_post/" + post.idPost

        // Logique pour enregistrer le post ici

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",
            body: JSON.stringify({
                title: (postToSave.title === '') ? post.title : postToSave.title,
                language: postToSave.language,
                content: (postToSave.content === '') ? post.content : postToSave.content,
                createdAt: postToSave.createdAt
            }),
        })

        if (response.status === 201) window.location.reload();
    }

    // enregistrement du post
    const handleCreation = async () => {

        const url = proxyUrl + "/new_post"
        const formData = new FormData();

        if (!image) {
            displaySnackbar(
                slang("Veuillez sélectionner une image svp", "Please an image is required"),
                slang("Select an image", "Choose an image"),
                "warning")
            return
        }

        formData.append('title', postToSave.title)
        formData.append('content', postToSave.content)
        formData.append('language', postToSave.language)
        formData.append('createdAt', postToSave.createdAt)
        formData.append('myImage', image)

        // Logique pour enregistrer le post ici

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Accept": "multipart/form-data",
            },
            body: formData,
            credentials: "include",
        })

        if (response.status === 201) window.location.reload();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (bool_modify) {

            handleModify();
        } else {

            handleCreation();
        }
    };

    const refresh_postToSave = function (myName, myValue) {

        const newComp = { ...postToSave, [myName]: myValue }

        setPostTosave(newComp);
    }

    return (
        <div>
            <h2>
                {bool_modify ?
                    t("Modifier ") + post.title
                    :
                    t("Créer un Post")
                }
            </h2>

            <form className="border-bottom border-secondary m-3" onSubmit={handleSubmit}>

                <button className="btn btn-lg fs-4 btn-primary my-2" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

                <MyInput
                    myName="title"
                    refresh_itemToSave={refresh_postToSave}
                    idGroupe={1}
                    myLabel={t("post.title")}
                    myType="text"
                />
                <MyTextArea
                    myName="content"
                    refresh_itemToSave={refresh_postToSave}
                    idGroupe={2}
                    myLabel={t("post.content")}
                    myType="text"
                />

                <MySelect
                    myName="language"
                    refresh_itemToSave={refresh_postToSave}
                    idGroupe={3}
                    myLabel={t("general.lang")}
                    data={languages.map(({ code, name }) => ([code, name]))}
                />

                <MyInput
                    myName="createdAt"
                    refresh_itemToSave={refresh_postToSave}
                    idGroupe={4}
                    myLabel={t("post.date")}
                    myType="date"
                />

                <MyImage
                    myLabel="image"
                    setImage={setImage}
                />

                <MyImagePreview
                    image={image}
                    id_image={post?.idPost}
                    bln_modify={bool_modify}
                    base_url={proxyUrl + "/uploads/0/id_image-" + rangDossiers.post + ".jpg"}
                />
            </form>
        </div>
    );
}

export default PostForm;