import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { digitGroup, displaySnackbar, userHasPriv, jump, liste_priv, slang, MyScrollButton, format_date_nohour } from "../Utils";
import SettingsLicenceForm from "./SettingsLicenceForm";
import PaginatedItems from "../Paginator";
const proxyUrl = require("../../../package.json").proxy;

const types_licences_dossier = [
    "Comptoir",
    "Cloud",
    "Menu",
    // "Cloud User", Plus nécessaire depuis que la date d'accès est directement inscrite dans DB_User
    "Assist distance",
    "Assist sur Site",
]

// Liste des Licences
const ListeLicences = function ({ lic_source, liste_options: ListeOptions }) {

    const { t } = useTranslation()

    const [currentItems, setCurrentItems] = useState([]);

    const table_titles = [
        "id",
        "Type",
        "Details",
        t("settings.comment"),
        "Options"
    ]

    return <>
        <PaginatedItems itemsPerPage={5} items={lic_source} setCurrentItems={setCurrentItems} />
        <p>nb: {currentItems.length + " / " + lic_source.length}</p>
        <table className="table table-hover">
            <thead>
                <tr>
                    {table_titles.map((title_elt, i) => (
                        <th key={i} scope="col">{title_elt}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {currentItems.map((lic_elt) => (
                    <tr key={lic_elt.idLicence}>
                        <th scope="row">{lic_elt.idLicence}</th>
                        <td>
                            <div className="col">
                                <div className="row">
                                    <p className="m-1 col">Licence</p>
                                    <p className="m-1 col" style={{color: "brown"}}>{types_licences_dossier[lic_elt.typeLicence - 1]}</p>
                                </div>
                                <div className="row">
                                    <p className="m-1 col">idMachine</p>
                                    <p className="m-1 col">{lic_elt.idMachine}</p>
                                </div>
                                <div className="row">
                                    <p className="m-1 col">idData</p>
                                    <p className="m-1 col">{lic_elt.idData}</p>
                                </div>
                                <div className="row">
                                    <p className="m-1 col">idDossClt</p>
                                    <p className="m-1 col">{lic_elt.idDossierClient}</p>
                                </div>

                                <p className="small">start {format_date_nohour(lic_elt.dateheureDebut)}</p>
                            </div>
                        </td>

                        <td>
                            <div className="col">
                                <div className="row">
                                    <p className="col m-1">nbJours</p>
                                    <p className="col m-1">{lic_elt.nbJours}</p>
                                </div>
                                <div className="row">
                                    <p className="col m-1">montant</p>
                                    <p className="col m-1">{digitGroup(lic_elt.montant)}</p>
                                </div>
                                <div className="row">
                                    <p className={"col m-1 " + (lic_elt.running ? "text-primary" : "")}>running</p>
                                    <p className={"col m-1 " + (lic_elt.running ? "text-primary" : "")}>{lic_elt.running ? "True" : "False"}</p>
                                </div>
                                <div className="row">
                                    <p className="col m-1">idResp</p>
                                    <p className="col m-1">{lic_elt.idResp ?? 0}</p>
                                </div>
                                <div className="row">
                                    <p className={"col m-1 " + (lic_elt.validated ? "text-success" : "")}>validated</p>
                                    <p className={"col m-1 " + (lic_elt.validated ? "text-success" : "")}>{lic_elt.validated ? "True" : "False"}</p>
                                </div>
                            </div>

                        </td>

                        <td>{lic_elt.comment}</td>

                        <ListeOptions lic={lic_elt} />
                    </tr>
                ))}
            </tbody>
        </table>
    </>
}


const SettingsLicences = function ({ user, parent_idDossierClient, switchValidation }) {

    const { t } = useTranslation()

    const [licences, setLicences] = useState([])
    const [licencesWaiting, setLicencesWaiting] = useState([])

    const [lic_selected, setLic_selected] = useState(null)

    const [confirmValidation, setConfirmValidation] = useState(false);

    const [formOpen, setFormOpen] = useState(false);

    const toggleForm = () => {
        if (formOpen) setLic_selected(null);
        setFormOpen(!formOpen);
    }

    const [refreshPage, setRefreshPage] = useState(false)

    const [current_selection, setCurrent_Selection] = useState({
        idUser: 0,
        idLicence: 0,
    })

    // Fetch Licences
    useEffect(() => {

        if (switchValidation) return

        (async () => {
            try {

                if (parent_idDossierClient === 0) return
                const response = await fetch(proxyUrl + '/licences', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        // "Accept": "multipart/form-data",
                    },
                    credentials: "include",
                    body: JSON.stringify({ idDossierClient: parent_idDossierClient })
                });

                const data = await response.json();

                setLicences(data);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [parent_idDossierClient, refreshPage, switchValidation])

    // Fetch Licences which are waiting for Validation
    useEffect(() => {

        if (!switchValidation) return

        (async () => {
            try {

                const response = await fetch(proxyUrl + '/licences_waiting');

                const data = await response.json();
                setLicencesWaiting(data);

            } catch (error) {
                console.error(error);
            }
        })();
    }, [refreshPage, switchValidation])


    const selectLic = (comp) => {

        setLic_selected(comp);

        toggleForm();

        if (!formOpen) jump("zone_modification")
    }

    // click on Validation Button
    const ValidateElement = (lic) => {

        (async () => {

            try {

                const response = await fetch(proxyUrl + "/licence/" + lic.idLicence, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({ ...lic, validated: true }),
                })

                if (response.status === 201) {

                    displaySnackbar("Licence " + lic.idLicence + slang(" validée !", " validated !"), slang("Succès", "Success"), "warning")
                    setRefreshPage(!refreshPage)
                }

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmValidation(false)
    }

    const validateElement_onclick = (id) => {

        setCurrent_Selection({ ...current_selection, idLicence: id })
        setConfirmValidation(!confirmValidation)
    }

    const ListeOptions = function ({ lic }) {

        const id = lic.idLicence

        return <td>
            <button className="btn" onClick={() => selectLic(lic)}>
                <img style={{ height: "40px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
            </button>
            {(userHasPriv(user, liste_priv.digibobo.admin) && (!lic.validated)) && <>
                <button className="btn" onClick={() => validateElement_onclick(id)}>
                    <img style={{ height: "40px", width: "auto" }} src="/images/validation.svg" alt="Corbeille" />
                </button>
                {(confirmValidation && (id === current_selection.idLicence)) &&
                    <button className="btn btn-danger" type="button" onClick={() => { ValidateElement(lic) }}>{t("general.confirm")} Validation</button>
                }
            </>
            }
        </td>
    }

    const ZoneModification = function () {

        return <div className="col mb-3">

            <div className="" id="zone_modification">

                <button className={"btn btn-lg fs-4 btn-" + (lic_selected === null ? (formOpen ? "outline-info" : "info") : "danger")} onClick={() => toggleForm()}>
                    {lic_selected === null ? t("settings.btn_create_lic") : t("ordi.btn_cancel_modify")}
                </button>
            </div>

            <div className="m-3">
                {formOpen && <SettingsLicenceForm 
                lic={lic_selected} 
                user={user} 
                parent_idDossierClient={parent_idDossierClient} 
                refreshLicences={()=>{
                    setFormOpen(false)
                    setLic_selected(null)
                    setRefreshPage(!refreshPage)
                }} />}
            </div>
        </div>
    }

    return <div className="mx-auto">

        {formOpen && <ZoneModification />}

        {!formOpen && <div>

            {(!switchValidation && (parent_idDossierClient + current_selection.idUser > 0)) && <>

                <div className="display-6" style={{ marginTop: "50px", marginBottom: "10px" }}>Licences</div>

                <ZoneModification />

                <div className="row m-3">
                    <ListeLicences lic_source={licences} liste_options={ListeOptions} />
                </div>
            </>
            }


            {(switchValidation && (userHasPriv(user, liste_priv.digibobo.admin))) && <>

                <div className="display-6" style={{ marginTop: "50px", marginBottom: "10px" }}>Waiting Licences</div>

                <ZoneModification />

                <div className="row m-3">
                    <ListeLicences lic_source={licencesWaiting} liste_options={ListeOptions} />
                </div>
            </>
            }

            <MyScrollButton />
        </div>
        }
    </div >
}

export default SettingsLicences;