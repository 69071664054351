import { useState, useEffect } from "react"
import { MyCheckBox, MyInput, MyInputSearch, MySelectMultiple, MySelectSearch } from "../FormElements";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { MyScrollButton, MySpinner, displaySnackbar, jump, liste_priv, slang } from "../Utils";
import PaginatedItems from "../Paginator";
const proxyUrl = require("../../../package.json").proxy;

const SettingsBigcode = function ({ user }) {

    const { t } = useTranslation()

    const [doss_clts, setDoss_clts] = useState([])

    const [stock, setStock] = useState([])

    const [stock_filtered, setStock_filtered] = useState([])

    const [stock_filtered_data, setStock_filtered_data] = useState([])

    const [pdts, setPdts] = useState([])

    const [pdts_selected, setPdts_selected] = useState([])

    const [pdts_filtered, setPdts_filtered] = useState([])

    const [pdts_filtered_data, setPdts_filtered_data] = useState([])

    const [current_idDossClt, setCurrent_idDossClt] = useState(0)

    const [current_idData, setCurrent_idData] = useState(0)

    const [confirmMergeOpen, setConfirmMergeOpen] = useState(false)

    const [choixOnglet, setChoixOnglet] = useState(0) // 0 = aucun, 1 = liste, 2 = stock, 3 = nouveau pdt

    const [pdtOpenToModif, setPdtOpenToModif] = useState()

    const [datas, setDatas] = useState([])

    const [showSpinner1, setShowSpinner1] = useState(false)
    const [showSpinner2, setShowSpinner2] = useState(false)

    const typesSelectData = {
        all: 0,
        none: 1,
        selection: 2
    }

    const listeOnglets = {
        pdts_liste: 1,
        pdts_stocks: 2,
        pdts_images: 3,
        pdts_nouveau: 4
    }

    // Fetch DossierClient
    useEffect(() => {

        (async () => {

            const lecture_priv_doss_clt = function () {

                // donne tous les idDossierClients pour lesquels liste_priv.dossier.produit est présent

                const liste_doss = []

                if (!user.privileges) return liste_doss

                user.privileges.forEach(p => {

                    if ((p.idDossierClient > 0) && (p.idData === 0)) {

                        if (p.priv.includes(liste_priv.dossier.produit)) liste_doss.push(p.idDossierClient)
                    }
                })

                return liste_doss

            }

            let crit_doss_clt = lecture_priv_doss_clt()

            if (crit_doss_clt.length === 0) crit_doss_clt = [0]

            try {
                const response = await fetch(proxyUrl + '/doss_clts_criteria', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        crit_doss_clts: { idDossierClient: { $in: crit_doss_clt } }
                    })
                });

                const data = await response.json();
                setDoss_clts(data);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [user.privileges])

    // Fetch Pdts
    useEffect(() => {

        (async () => {

            try {

                if (current_idDossClt === 0) return

                setShowSpinner1(true)

                const response = await fetch(proxyUrl + '/pdts/' + current_idDossClt);

                setShowSpinner1(false)

                const data = await response.json();
                setPdts(data);
                setPdts_filtered(data);
                setPdts_filtered_data(data);

            } catch (error) {
                console.error(error);
            }


        })();
    }, [current_idDossClt])

    // Fetch Stocks Actuels
    useEffect(() => {

        (async () => {
            try {


                if (current_idDossClt === 0) return

                setShowSpinner2(true)

                const response = await fetch(proxyUrl + '/stock_actuel/' + current_idDossClt);

                setShowSpinner2(false)

                const data = await response.json();
                setStock(data);
                setStock_filtered(data);


            } catch (error) {
                console.error(error);
            }
        })();
    }, [current_idDossClt])

    // Fetch Datas
    useEffect(() => {

        if (current_idDossClt === 0) return

        const fetchData = async () => {
            try {

                const response = await fetch(proxyUrl + '/datas/' + current_idDossClt)

                const data = await response.json();

                setDatas(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [current_idDossClt])


    // Fonctions supports...

    // ...générale
    const getNomProduit = function (pdt) {

        return pdt.NomProduit + " (" + pdt.IdProduit + ") - data " + pdt.idData
    }
    // ...du Merge, Price Modification, New Product
    const create_price_pending = async function ({ typeElement, idData, idLigne, bigcode, price }) {

        const body = {
            idData: idData,
            typeElement: typeElement,
            idLigne: idLigne,
            bigCode: bigcode,
            price: price,
            idUser: user.idUser,
        }

        return await fetch(proxyUrl + "/pending", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",

            // Pending : 1. Reset pwd  2. Remise accordée  3. Suppr tk accordée  4. Transfert validé
            //   5. Transfert en attente de validation  6. Transfert refusé  7. maj PV1 Pdt  8. maj PV2 Pdt  9. maj PV3 Pdt  10. maj PA Pdt  11. maj bigCode Pdt

            body: JSON.stringify(body),
        })
    }

    const create_new_pdt = async function ({ idData, nomProduit, barcode, stockable, estFluide, bigCode, pv1, pv2, pv3, pa }) {

        const body = {
            idData: idData,
            typeElement: 12,
            idLigne: 0,
            bigCode: bigCode,
            idUser: user.idUser,
            content: {
                nomProduit: nomProduit,
                barcode: barcode,
                stockable: stockable,
                estFluide: estFluide,
                pv1: pv1,
                pv2: pv2,
                pv3: pv3,
                pa: pa,
            }
        }

        return await fetch(proxyUrl + "/pending", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Accept": "multipart/form-data",
            },
            credentials: "include",

            body: JSON.stringify(body),
        })
    }

    // Select One Product
    const selectOnePdt = function (pdt) {
        const pdts_curr = pdts_selected.slice();

        pdts_curr.push(pdt)

        setPdts_selected(pdts_curr)

        setConfirmMergeOpen(false)

        displaySnackbar(getNomProduit(pdt) + slang(" ajouté à la liste", " added to the merge list"), slang("Succès", "Success"), "info")
    }

    // Fusionne les produits sélectionnés (envoi le même bigcode)
    const mergeProducts = async function () {

        // promise all fetch --> pour création de pendings

        let bigCodeRef = 0 // bigCode qu'il va falloir donner à tous les produits de la liste
        let idxRef = 0 // index du produit dont le bigCode va être donné à tous les autres
        let shouldSkip = false;
        let message = slang("Rapport de Fusion", "Merge report") + " :\n";

        const pdts_to_merge = [...new Set(pdts_selected)] // j'enlève les doublons

        pdts_to_merge.forEach((p, idx) => {

            if (shouldSkip) return

            if (p.bigCode) { // on retient le 1er bigcode qui apparaît et son index

                bigCodeRef = pdts_to_merge[0].bigCode
                idxRef = idx
                shouldSkip = true;
                return
            }
        })

        await Promise.all(pdts_to_merge.map(async (p, idx) => {

            if (idx === idxRef) return // pour qu'on ne perde pas le temps à redonner le même bigcode

            const response = await create_price_pending({
                typeElement: 11,
                idData: p.idData,
                idLigne: p.IdProduit,
                bigcode: bigCodeRef,
                price: 0,
            })

            message = message + getNomProduit(p) + " : " + ((response.status === 201) ? "true" : "false") + "\n"

        }));

        displaySnackbar(message, "Success", "success")

        setPdts_selected([])
    }


    // Formulaire de modification des prix d'un Produit
    const ModifPdtPricesForm = function ({ pdt }) {
        const [newPending, setNewPending] = useState({
            idUser: user.idUser,
            pv1: undefined,
            pv2: undefined,
            pv3: undefined,
            pa: undefined,
        })

        const [selectData, setSelectData] = useState(typesSelectData.all)

        // Product's Price Modification
        const modifyProduct = async function (e) {

            let message = getNomProduit(pdt) + "\n"

            e.preventDefault()

            // on tient compte du réglage All data le cas échéant

            if (selectData === typesSelectData.all) {
                newPending.idData = datas.map((data) => (data.idData))
            } else if ((selectData === typesSelectData.none) || (newPending.idData.length === 0)) { // on se rassure qu'il y a au moins 1 data où créer le produit
                displaySnackbar(slang("Sélectionnez au moins une base de données", "Please select at least one database"), "Warning", "warning")
                return
            }

            const prices = [
                [newPending.pv1, 7],
                [newPending.pv2, 8],
                [newPending.pv3, 9],
                [newPending.pa, 10]
            ]

            await Promise.all(prices.map(async (p) => {

                newPending.idData.forEach(async d => {

                    if (!p[0]) return

                    const response = await create_price_pending({
                        typeElement: p[1],
                        idData: d,
                        idLigne: pdt.IdProduit,
                        bigcode: 0,
                        price: p[0],
                    })

                    if (response.status === 201) {
                        message = message + slang("Prix modifié ", "Price changed ") + (p[1] - 6) + " data " + d + " : " + p[0] + "\n"

                    }

                })
            }))




            document.getElementById("pdt-modify-prices-form").reset();

            setNewPending({ ...newPending, pv1: undefined, pv2: undefined, pv3: undefined, pa: undefined })
        }

        // Compose new Pending
        const compose_pending = function (myName, myValue) {

            const newSelection = { ...newPending, [myName]: myValue };

            setNewPending(newSelection);
        }

        return <>
            {(pdt) && <>

                <h3>{t("general.modify") + " " + getNomProduit(pdt)}</h3>
                <form id="pdt-modify-prices-form" onSubmit={modifyProduct}>
                    <div className="row row-cols-auto">

                        <MyInput
                            myName="pv1"
                            refresh_itemToSave={compose_pending}
                            idGroupe={1}
                            myLabel={"pv1"}
                            myType="Number"
                            step={0.01}
                        />
                        <MyInput
                            myName="pv2"
                            refresh_itemToSave={compose_pending}
                            idGroupe={2}
                            myLabel={"pv2"}
                            myType="Number"
                            step={0.01}
                        />
                        <MyInput
                            myName="pv3"
                            refresh_itemToSave={compose_pending}
                            idGroupe={3}
                            myLabel={"pv3"}
                            myType="Number"
                            step={0.01}
                        />
                        {!pdt.EstPack && // on ne peut modifier un PA que pour les produits qui ne sont pas des packs
                            <MyInput
                                myName="pa"
                                refresh_itemToSave={compose_pending}
                                idGroupe={4}
                                myLabel={"pa"}
                                myType="Number"
                                step={0.01}
                            />
                        }
                    </div>
                    <div className="row col-lg-8 col-md-10 col-sm-12">
                        <MySelectMultiple
                            myName="idData"
                            refresh_itemToSave={compose_pending}
                            idGroupe={2}
                            myLabel="Data"
                            data={datas.map((d) => ([d.idData, d.idData + " - " + d.denomination.substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                            func={parseInt}
                        />

                        <div className="row g-1">
                            <button className="btn btn-secondary fs-5 col m-1" type="button" onClick={() => setSelectData(typesSelectData.all)}>All Data</button>
                            <button className="btn btn-secondary fs-5 col m-1" type="button" onClick={() => setSelectData(typesSelectData.none)}>None</button>
                            <span className="border border-dark fs-5 col m-1 text-center">{["ALL", "NONE", "Selection"][selectData]}</span>
                        </div>

                    </div>

                    <div className="row m-3 justify-content-end">

                        <button className="col-4 btn btn-lg fs-4 btn-success m-1" type="submit">{t("general.modify") + " " + getNomProduit(pdt)}</button>
                        <button className="col-4 btn btn-lg fs-4 btn-outline-danger m-1" type="button" onClick={() => {
                            setPdtOpenToModif(null)
                        }}>{t("general.cancel")}</button>
                    </div>
                </form>
            </>
            }
        </>
    }

    // Formulaire de Création d'un nouveau Produit
    const CreatePdtForm = function () {

        const basicPending = {
            idUser: user.idUser,
            nomProduit: "",
            barcode: "",
            idData: [],
            pv1: 0,
            pv2: 0,
            pv3: 0,
            pa: 0,
            stockable: false,
            estFluide: false,
        }

        const [newPending, setNewPending] = useState(basicPending)

        const [selectData, setSelectData] = useState(typesSelectData.all)

        // Product's Creation
        const createProduct = async function (e) {

            e.preventDefault()

            // on tient compte du réglage All data le cas échéant

            if (selectData === typesSelectData.all) {
                newPending.idData = datas.map((data) => (data.idData))
            } else if ((selectData === typesSelectData.none) || (newPending.idData.length === 0)) { // on se rassure qu'il y a au moins 1 data où créer le produit
                displaySnackbar(slang("Sélectionnez au moins une base de données", "Please select at least one database"), "Warning", "warning")
                return
            }

            // on récupère d'abord un bigCode

            const response = await fetch(proxyUrl + '/pdt_bigcode')

            let seqId = 0

            if (response.status === 201) {

                seqId = await response.json()

            } else {
                return
            }


            newPending.idData.forEach(async d => {

                const body = {
                    idData: d,
                    nomProduit: newPending.nomProduit,
                    barcode: newPending.barcode,
                    stockable: newPending.stockable,
                    estFluide: newPending.estFluide,
                    bigCode: seqId,
                    pv1: newPending.pv1,
                    pv2: newPending.pv2,
                    pv3: newPending.pv3,
                    pa: newPending.pa,
                }

                // { idData, barcode, stockable, estFluide, bigCode, pv1, pv2, pv3, pa }

                const response2 = await create_new_pdt(body)

                if (response2.status === 201) {

                    displaySnackbar(slang("Produit correctement ajouté dans le data_", "Product created successfully inserted in data_") + d, "Success", "success")

                    document.getElementById("create-product-form").reset();
                }
            });

            setNewPending(basicPending)

        }

        // Compose new Pending
        const compose_pending = function (myName, myValue) {

            if (myName === "idData") setSelectData(typesSelectData.selection)

            const newSelection = { ...newPending, [myName]: myValue };

            setNewPending(newSelection);
        }

        return <>
            <form id="create-product-form" onSubmit={createProduct}>
                <div className="row">
                    <div className="col">

                        <MyInput
                            myName="nomProduit"
                            refresh_itemToSave={compose_pending}
                            idGroupe={1}
                            myLabel={"Pdt"}
                            myType="text"
                        />

                        <MyCheckBox
                            myLabel={t("settings.stock")}
                            myName="stockable"
                            refresh_itemToSave={compose_pending}
                            idGroupe={3}
                        />

                        <MyCheckBox
                            myLabel={t("settings.fluid")}
                            myName="estFluide"
                            refresh_itemToSave={compose_pending}
                            idGroupe={4}
                        />


                        <MySelectMultiple
                            myName="idData"
                            refresh_itemToSave={compose_pending}
                            idGroupe={2}
                            myLabel="Data"
                            data={datas.map((d) => ([d.idData, d.idData + " - " + d.denomination.substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                            func={parseInt}
                        />

                        <div className="row g-1">
                            <button className="btn btn-secondary fs-5 col m-1" type="button" onClick={() => setSelectData(typesSelectData.all)}>All Data</button>
                            <button className="btn btn-secondary fs-5 col m-1" type="button" onClick={() => setSelectData(typesSelectData.none)}>None</button>
                            <span className="border border-dark fs-5 col m-1 text-center">{["ALL", "NONE", "Selection"][selectData]}</span>
                        </div>


                        <button className="btn btn-lg fs-4 btn-success m-1" type="submit">{t("general.add") + " " + t("settings.onglet8")}</button>
                    </div>

                    <div className="col">
                        <MyInput
                            myName="barcode"
                            refresh_itemToSave={compose_pending}
                            idGroupe={5}
                            myLabel="barcode"
                            myType="text"
                        />
                        <MyInput
                            myName="pv1"
                            refresh_itemToSave={compose_pending}
                            idGroupe={6}
                            myLabel="pv1"
                            myType="number"
                            step={0.01}
                        />
                        <MyInput
                            myName="pv2"
                            refresh_itemToSave={compose_pending}
                            idGroupe={7}
                            myLabel="pv2"
                            myType="number"
                            step={0.01}
                        />
                        <MyInput
                            myName="pv3"
                            refresh_itemToSave={compose_pending}
                            idGroupe={8}
                            myLabel="pv3"
                            myType="number"
                            step={0.01}
                        />

                        <MyInput
                            myName="pa"
                            refresh_itemToSave={compose_pending}
                            idGroupe={9}
                            myLabel="pa"
                            myType="number"
                            step={0.01}
                        />

                    </div>
                </div>
            </form>
        </>
    }

    // Liste des Produits dans le dossier actuel
    const ListeOptions = function ({ pdt }) {

        return <td>
            <button className="btn" onClick={() => selectOnePdt(pdt)}>
                <img style={{ height: "40px", width: "auto" }} src="/images/select.svg" alt="Crayon" />
            </button>

            <button className="btn" onClick={() => {
                setPdtOpenToModif(pdt)
                jump("formModifProduit")
            }
            }>
                <img style={{ height: "35px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
            </button>
        </td>
    }
    const ListePdts = function () {

        const [currentItems, setCurrentItems] = useState([]);

        if (current_idDossClt === 0) return <></>

        const LittleYesNo = function ({ bool }) {

            if (bool) return <span style={{ color: "green" }}>Yes</span>

            return <span style={{ color: "gray" }}>No</span>
        }

        return <>
            <PaginatedItems itemsPerPage={30} items={pdts_filtered} setCurrentItems={setCurrentItems} />
            <p>nb: {currentItems.length + " / " + pdts_filtered.length}</p>
            <table className="table">
                <thead>
                    <tr>
                        {[
                            "big",
                            t("general.name") + " (local_id) - data",
                            "Details",
                            "Options"
                        ].map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((pdt_elt, idx) => (
                        <tr key={idx}>
                            <th scope="row">{pdt_elt.bigCode}</th>

                            <td>{getNomProduit(pdt_elt)}</td>

                            <td className="row g-0">
                                <div className="col">
                                    <p className="m-0 p-0">stk <LittleYesNo bool={pdt_elt.Stockable} /></p>
                                    <p className="m-0 p-0">pack <LittleYesNo bool={pdt_elt.EstPack} /></p>
                                </div>

                                <div className="col">
                                    <p className="m-0 p-0">fract <LittleYesNo bool={pdt_elt.EstFractionnable} /></p>
                                    <p className="m-0 p-0">fluid <LittleYesNo bool={pdt_elt.estFluide} /></p>
                                </div>
                            </td>

                            <ListeOptions pdt={pdt_elt} />
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }

    // Liste des stocks dans le dossier actuel
    const ListeStock = function () {

        const [currentItems, setCurrentItems] = useState([]);

        if (current_idDossClt === 0) return <></>

        const table_titles = [
            "big",
            t("general.name") + " (local_id) - data",
            t("pending.table_qte"),
            t("settings.magasin")
        ]

        return <>
            <PaginatedItems itemsPerPage={30} items={stock_filtered} setCurrentItems={setCurrentItems} />
            <p>nb: {currentItems.length + " / " + stock_filtered.length}</p>
            <table className="table">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((sk_elt, idx) => (
                        <tr key={idx}>
                            <th scope="row">{sk_elt.bigCode}</th>

                            <td>{getNomProduit(sk_elt)}</td>

                            <td>{sk_elt.Qte}</td>

                            <td>{sk_elt.NomMag}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    }


    return <div className="col">
        <h2>Produits</h2>

        {/* SELECTIONNE UN DOSSIER */}

        <div className="col-12 col-md-8">

            <MySelectSearch
                myName="idDossierClient"
                refresh_itemToSave={(name, value) => {
                    if (isNaN(value)) value = 0
                    setCurrent_idDossClt(value)
                }}
                idGroupe={1}
                myLabel={t("settings.doss_clt")}
                data={doss_clts.map((d) => ([d.idDossierClient, d.idDossierClient + " - " + d.nomClient]))}
                func={parseInt}
            />
        </div>

        {(current_idDossClt > 0) &&
            <div>

                <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">

                    <button type="button" className={"btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_liste ? "primary" : "outline-secondary")} onClick={() => setChoixOnglet(listeOnglets.pdts_liste)}>{t("settings.onglet7")}</button>
                    <button type="button" className={"btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_stocks ? "primary" : "outline-secondary")} onClick={() => setChoixOnglet(listeOnglets.pdts_stocks)}>STOCK</button>
                    <button type="button" className={"btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_images ? "primary" : "outline-secondary")} onClick={() => setChoixOnglet(listeOnglets.pdts_images)}>IMAGES</button>
                    <button type="button" className={"btn btn-lg btn-" + (choixOnglet === listeOnglets.pdts_nouveau ? "primary" : "outline-secondary")} onClick={() => setChoixOnglet(listeOnglets.pdts_nouveau)}>{t("settings.onglet8")}</button>
                </div>
            </div>
        }

        {/* FILTRE PAR DATA, ONGLET LISTE ET IMAGES */}

        {
            ((current_idDossClt > 0) && (choixOnglet === listeOnglets.pdts_liste) && (choixOnglet === listeOnglets.pdts_images)) &&

            < div className="col-12 col-md-8 mt-3">

                <MySelectSearch
                    myName="idData"
                    refresh_itemToSave={(name, value) => {
                        if (value === 0) {

                            setPdts_filtered_data(pdts)
                        } else {

                            const pdts_filt = pdts.filter(p => (p.idData === value))
                            setCurrent_idData(value)
                            setPdts_filtered_data(pdts_filt)
                            setPdts_filtered(pdts_filt)
                        }
                    }}
                    idGroupe={3}
                    myLabel={"Data"}
                    data={datas.map((d) => ([d.idData, d.idData + " - " + d.denomination.substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                    func={parseInt}
                />
            </div>
        }

        {/* ONGLET LISTE PDTS */}

        {
            (choixOnglet === listeOnglets.pdts_liste) &&
            <>

                {/* FORMULAIRE DE MODIFICATION DES PRIX */}

                <ModifPdtPricesForm pdt={pdtOpenToModif} />

                {(!pdtOpenToModif) &&
                    <>

                        {/* FUSION DE PRODUITS */}

                        {pdts_selected.length > 0 && <div className="mb-3 mt-3">

                            <h4>Pdts Selection</h4>

                            <div className="mb-1">
                                {pdts_selected.map((elt, idx) => {
                                    return <button className="btn btn-lg fs-4 btn-outline-primary" key={idx} onClick={(e) => {
                                        const pdts_curr = pdts_selected.slice();

                                        pdts_curr.splice(idx, 1)

                                        setPdts_selected(pdts_curr)
                                    }}>{elt.NomProduit + " (" + elt.IdProduit + "-" + elt.idData + ")"}</button>
                                })}
                            </div>

                            {pdts_selected.length > 1 && <>
                                <button className="btn btn-lg fs-4 btn-info" onClick={() => { setConfirmMergeOpen(!confirmMergeOpen) }}>
                                    {t("settings.pdtMerge") + " Pdts"}
                                </button>
                                {confirmMergeOpen &&
                                    <button className="btn btn-lg fs-4 btn-warning" onClick={mergeProducts}>
                                        {t("general.confirm") + " " + t("settings.pdtMerge")}
                                    </button>
                                }
                            </>
                            }
                            <button className="btn btn-lg fs-4 btn-outline-danger m-1" type="button" onClick={() => setPdts_selected([])}>{t("general.cancel")}</button>
                        </div>}


                        {/* FILTRE LA LISTE */}

                        < div className="col-12 col-md-8 input-group mb-1 fs-5">
                            <MyInputSearch
                                myLabel={t("settings.search_pdt")}
                                idGroupe={2}
                                items={pdts_filtered_data}
                                setItemsFiltered={setPdts_filtered}
                                filt1="IdProduit"
                                filt2="NomProduit"
                            />
                        </div>

                        {/* ANCRE + DOWNLOAD */}

                        <div id="formModifProduit"></div>

                        <CSVLink className="btn btn-lg fs-4 btn-outline-success mt-3 mb-1" data={pdts_filtered} filename={"pdts_list_filtered"}>{t("general.download")}</CSVLink>

                        {/* LISTE DE PRODUITS */}

                        <MySpinner show={showSpinner1} myText={t("general.loading") + " Pdts"} />

                        <ListePdts />
                    </>
                }
            </>
        }

        {/* ONGLET IMAGES */}

        {
            ((choixOnglet === listeOnglets.pdts_images) && (current_idData > 0)) &&

            <form id="form_images" action="/create_png_images" method="POST" encType="multipart/form-data">
                <label htmlFor="file">Select your PNG images:</label>
                <input type="file" multiple="multiple" accept=".png, .jpg, .jpeg" name="myImages" id="file" />
                <input type="number" value={current_idData} hidden readOnly name="idData" />
                <button type="submit">upload</button>
            </form>
        }

        {/* ONGLET STOCKS */}

        {
            (choixOnglet === listeOnglets.pdts_stocks) &&
            <>

                {/* FILTRE LA LISTE */}

                <div className="col-12 col-md-8 input-group mb-1 fs-5">
                    <MyInputSearch
                        myLabel={t("settings.search_pdt")}
                        idGroupe={4}
                        items={stock_filtered_data}
                        setItemsFiltered={setStock_filtered}
                        filt1="IdProduit"
                        filt2="NomProduit"
                    />
                </div>

                <div className="col-12 col-md-8 mt-3">
                    <MySelectSearch
                        myName="idData"
                        refresh_itemToSave={(name, value) => {
                            if (value === 0) {

                                setStock_filtered_data(stock)
                            } else {

                                const stock_filt = stock.filter(s => (s.idData === value))
                                setCurrent_idData(value)
                                setStock_filtered_data(stock_filt)
                                setStock_filtered(stock_filt)
                            }
                        }}
                        idGroupe={5}
                        myLabel={"Data"}
                        data={datas.map((d) => ([d.idData, d.idData + " - " + d.denomination.substring(0, 25) + " - " + (d.comment ?? "").substring(0, 30)]))}
                        func={parseInt}
                    />
                </div>

                {/* TELECHARGEMENT DE STOCK */}

                <CSVLink className="btn btn-lg fs-4 btn-outline-success mt-3 mb-1" data={stock_filtered} filename={"stocks_list_filtered"}>{t("general.download")}</CSVLink>

                {/* LISTE DE STOCK */}

                <MySpinner show={showSpinner2} myText={t("general.loading") + " Stocks"} />

                <ListeStock />
            </>
        }

        {/* ONGLET FORMULAIRE DE CREATION D'UN PRODUIT */}

        {
            ((choixOnglet === listeOnglets.pdts_nouveau) && (current_idDossClt > 0)) &&
            <>

                <CreatePdtForm />
            </>
        }

        <MyScrollButton />
    </div >
}

export default SettingsBigcode;